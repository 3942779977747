import React, { useState, useEffect, useRef } from 'react'
import ApartmentIcon from '@mui/icons-material/Apartment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import Group from '@mui/icons-material/Group';
import { Link, useLocation } from 'react-router-dom';
import Validation from '../LoginValidation';
import LoginForm from './LoginForm';
import TwitterIcon from '@mui/icons-material/X';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Container, Row, Col, Card } from 'react-bootstrap';
import http from '../http'
import MapChart from './MapChart';
// import { Tooltip } from "react-tooltip";
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import ImgAPPGBC from '../assets/img/appgbc.png'
import ImgAPPGAI from '../assets/img/appgai.png'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Image from 'react-bootstrap/Image';
import pvlnImg from '../assets/img/connectpvlns.png';
import BICBg from '../assets/img/bicbgrnd.jpeg'
// import pb1 from '../assets/img/image1.png'
// import pb2 from '../assets/img/image2.png'
// import pb3 from '../assets/img/image3.png'
// import pl1 from '../assets/img/appgai.png'
// import pl2 from '../assets/img/appgbc.png'
import pl3 from '../assets/img/bicpavilion.png'

import CardGroup from 'react-bootstrap/CardGroup';
import Stack from 'react-bootstrap/Stack';
import PreLoader from './PreLoader';
import { PLSIZE } from './PavilionConstants';


function HomeContent() {

    const [progress, setProgress] = useState(true);
    const [content, setContent] = useState('')
    const [pavilions, setPavilions] = useState([]);
    const theme = localStorage.getItem('data-bs-theme');
    const [countrylist, setCountrylist] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [countryData, setcountryData] = useState('');
    const handleInputChange = (event) => {
        // Update inputValue state with the value from the input field
        //setInputValue(event.target.value);
        setSelectedOption(countryData);
        setInputValue(document.getElementById('floatingSearch').value);

        http().get('/webpublicpavilions?country_id=' + countryData + '&name=' + event.target.value).then((data) => {
            setPavilions(data.data.data.pavilions.data);
        });


    };
    const handleSelectChange = (event) => {
        // Update the selectedOption state when the dropdown selection changes
        //setSelectedOption(event.target.value);
        setInputValue(document.getElementById('floatingSearch').value);
        if (event.target.value == 'Global') {
            setcountryData('');
            http().get('/webpublicpavilions?country_id=&name=' + inputValue).then((data) => {
                setPavilions(data.data.data.pavilions.data);
            });
        } else {
            setcountryData(event.target.value);
            http().get('/webpublicpavilions?country_id=' + event.target.value + '&name=' + inputValue).then((data) => {
                setPavilions(data.data.data.pavilions.data);
            });
        }
    };
    const [bucketName, setBucketName] = useState([]);

    useEffect(() => {
        http().get('/s3bucketname').then((data) => {
            setBucketName(data.data.data.bucketName);
        });

        http().get('/getcountries').then((data) => {
            setCountrylist(data.data.data.countries);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });
        http().get('/webpublicpavilions').then((res) => {
            setPavilions(...pavilions, res.data.data.pavilions.data);
            setProgress(false)
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });
    }, [])

    return (
        <div >
            {/*
      <header id="myHeader" className="mb-0 mt-5 mt-sm-5 bg-home">
        <div className="container col-xxl-8 mx-0 mx-lg-5 px-0 pt-5 ">
            <div className="row flex-lg-row-reverse align-items-center g-5 pt-5">
                <div className="col-10 col-sm-8 col-lg-6">
                    &nbsp;
                </div>
                <div className="col-lg-6 p-0 my-5" data-aos="slide-right">
                    <h3 className="fw-bold text-primary lh-1 mb-3">Welcome to Pavilion 3.0</h3>
                    <p className="lead">Share your professional interests, contribute and be connected with your community.</p>
                    <div className="col-12 mx-0 col-sm-8 col-lg-12">
                        <div>
                            <div className="divider-1 w-100 border-bottom position-relative mt-4 mb-4"></div>
                        </div>
                        <div className="row h-100">
                            <div className="col-6">
                                <div className="card m-1 border-0">
                                    <a href="/social/google" style={{backgroundColor: '#dd4b39'}}
                                        className="btn btn-primary border-0 d-flex align-items-center justify-content-center"
                                         aria-pressed="true"><GoogleIcon/> Google</a>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card m-1 border-0">
                                    <a href="/social/twitter" style={{backgroundColor:'#55acee'}} 
                                        className="btn btn-primary border-0 d-flex align-items-center justify-content-center"
                                        aria-pressed="true"><TwitterIcon/> Twitter</a>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card m-1 border-0">
                                    <a href="/social/facebook" style={{backgroundColor: '#3B5998'}}
                                        className="btn btn-primary border-0 d-flex align-items-center justify-content-center"
                                         aria-pressed="true"><FacebookIcon/> Facebook</a>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card m-1 border-0">
                                    <a href="/social/linkedin" style={{backgroundColor:'#007bb5'}} 
                                        className="btn btn-primary border-0 d-flex align-items-center justify-content-center"
                                        aria-pressed="true"><LinkedInIcon/> Linked In</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="divider-2 w-100 border-bottom position-relative mt-4 mb-4"></div>
                        </div>
                        <LoginForm/>
                    </div>
                </div>
            </div>
        </div>
    </header> */}
            <main><>
                <Container fluid className="bg-primary" style={{ marginTop: '115px' }}>
                    <Row >
                        <Col md={3} className='d-flex align-items-center order-1 order-md-0'>
                            <div className="col-lg-12 bg-light rounded rounded-4 p-3 m-2 shadow" data-aos="slide-right">
                                <LoginForm />
                            </div>
                        </Col>
                        <Col md={9} className='mapchart order-0 order-md-1'>
                            <MapChart setTooltipContent={setContent} />
                            {/* <Tooltip content={content} /> */}
                        </Col>
                    </Row>

                </Container>

                {/* <Container className="my-5 p-5 bg-toggle" >
                    <Row className='' >
                        <Col md={9} xs={12}>
                            <h3 class=" text-primary lh-1 mb-3">Global Pavilions</h3>
                            <p className='fs-5' >
                            Join the Pavilion community, where thought leaders and forums from around the world gather in one place! Witness innovations and stories come to life, and meet entrepreneurs, innovators, artists, business leaders, and policymakers from near and far. 
                            </p>
                        </Col>
                        <Col md={3} xs={12} className='d-flex align-items-center justify-content-end '>
                            <Button variant='outline-primary' className='fw-bold fs-5 ' size="md"><AccountBalanceIcon /> Open Pavilion</Button>
                            <a class="btn btn-outline-primary btn-lg" href="/plans" role="button"><AccountBalanceIcon/> Open Pavilion</a> 
                        </Col>
                    </Row>
                </Container> */}
                <Container className='my-5'>
                    <Row>
                        <Col md={5}><Image src={pvlnImg} width='100%' /></Col>
                        <Col md={7} className='d-flex flex-column justify-content-center align-items-start mt-3 mt-md-0'>
                            <h3 class=" text-primary lh-1 mb-3">Global Pavilions</h3>
                            <h4 class="fs-5 text-secondary lh-1 mb-3">Welcome to Pavilion: The Premier Platform for Technology and Innovation Professionals</h4>
                            <p className='fs-6 mb-3' >
                                Join the Pavilion community, where thought leaders and forums from around the world gather in one place! Witness innovations and stories come to life, and meet entrepreneurs, innovators, artists, business leaders, and policymakers from near and far.
                            </p>
                            <p className='fs-6 mb-3'>
                                {/* Welcome to the Pavilion 3.0, the ultimate social media destination for professionals, to connect, collaborate and make a difference together. Already joined by business leaders, academics, entrepreneurs, investors and policy makers, the Pavilion platform empowers you to share your professional interests and be a part of something bigger. */}
                                Engage in global discussions, showcases, and events. Supercharge your resource base with dynamic videos and insightful writings, and watch ideas and cultures emerge before your eyes. Set up your own pavilion using our crowdsourcing platform or pavilion shop feature and connect with each unique pavilion. Grow your network, gain insights, and enhance your business success through unparalleled collaborations.
                            </p>
                            <p className='fs-6 mb-3'>Be part of creating a new world with Pavilion. Join us today!</p>
                            <p className='fs-6 mb-3'>#Pavilion #Innovate #Collaborate #GlobalCommunity #ThoughtLeaders #BusinessSuccess</p>

                            <a class="btn btn-outline-primary p-2 btn-md" href="/plans" role="button"><AccountBalanceIcon /> Open Pavilion</a>


                        </Col>

                    </Row>
                </Container>
                {/* <Container  id='allpavilions'> */}
                {/* <Row className='mb-3'>
                        <Col md={4}><h3 class="text-primary lh-1 mb-3">All Pavilions</h3></Col>
                        <Col md={8} className='fs-4 '>Empower yourself to co-create the future where everyone's vision
                        connects</Col>
                    </Row> */}
                {/* <hr className="solid mb-5" /> */}
                {/* <h4 className="row px-5 py-3"> */}
                {/* <h3 className="text-center text-primary">Empower yourself to co-create the future where everyone's vision
                        connects</h3> */}
                {/* <div className="col-12 d-flex flex-wrap justify-content-center ">
                        <h4 className="m-4 d-flex align-items-center">
                            <ApartmentIcon />
                            Companies
                        </h4>
                        <h4 className="m-4 d-flex align-items-center">
                            <MenuBookIcon />
                            Academia
                        </h4>
                        <h4 className="m-4 d-flex align-items-center">
                            <AssuredWorkloadIcon />
                            Government
                        </h4>
                        <h4 className="m-4 d-flex align-items-center">
                            <Group />
                            Citizens
                        </h4>
                    </div>  */}
                {/* </Container> */}
                {/* <div className="container text-center" id="allpavilions">
                    <form className="p-0 p-md-0 rounded-3 bg-light d-flex justify-content-center">
                        <div className="input-group mb-5 px-lg-5 mx-lg-5 px-xs-0 mx-xs-0" data-aos="fade-up">
                            <input type="search" className="form-control rounded" id="floatingSearch" placeholder="Search Pavilions" />
                            <select className="form-select form-select-md ms-3 rounded" id="inputGroupSelect04">
                                <option defaultValue='Global'>Global</option>
                                <option value="1">United Kingdom</option>
                                <option value="2">South Africa</option>
                                <option value="3">India</option>
                            </select>
                        </div>
                    </form>
                </div> */}

                <Container id='allpavilions' className='bg-secondary rounded rounded-2 bg-opacity-25'>
                    <Row>
                        <Col md={8} className='p-2 d-flex align-items-center'>
                            <Button variant='outline-light' size='sm' className='fw-bold fs-5 ' size="md">
                                <ApartmentIcon /> Companies
                            </Button>
                            <Button variant='outline-light' size='sm' className='fw-bold fs-5 ' size="md">
                                <MenuBookIcon /> Universities
                            </Button>
                            <Button variant='outline-light' size='sm' className='fw-bold fs-5 ' size="md">
                                <AssuredWorkloadIcon /> Government
                            </Button>
                            <Button variant='outline-light ' size='sm' className='fw-bold fs-5 ' size="md">
                                <Group /> Citizens
                            </Button>

                        </Col>
                        <Col md={4} className='p-2'>
                            <Form className="d-flex justify-content-center">
                                <div className="input-group" data-aos="fade-up">
                                    <input type="search" className="form-control rounded" id="floatingSearch" placeholder="Search Pavilions" onBlur={handleInputChange} />
                                    <select className="form-select form-select-md ms-3 rounded" id="inputGroupSelect04" onChange={handleSelectChange}>
                                        <option defaultValue='Global'>Global</option>
                                        {countrylist.map((countrylistArray, countrylistIndex) => (
                                            <option value={countrylistArray.id}>{countrylistArray.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                {/* <hr className="solid " /> */}
                <Container>
                    {/* <Row className="p-5 mx-auto mx-sm-5 "> */}
                    <CardGroup className='my-5 d-flex justify-content-center'>

                        {/* <h3 className="text-center text-primary pb-1" data-aos="zoom-in">Pavilions</h3>
                    <p className="text-center pb-5" data-aos="zoom-in">The world's most easy way to organise projects of professional communities and
                        crowdsource funding. <br />By setting up a Pavilion or joining Pavilions, professionals can share their
                        professional interests and build open project communities.</p> */}
                        {progress ? (<PreLoader style={{ textAlign: 'center' }} size={PLSIZE} />) : (
                            <Row className='gx-3'>
                                {pavilions.map((innerArray, outerIndex) => (
                                    // // <Col key={outerIndex} md={4} xs={12} className='d-flex justify-content-center'>
                                    //     <Link to={`http://localhost:3000/homepavilion/pavilion/${innerArray.id}`}>
                                    //         <Card className="bg-dark text-white border-0 pcard" data-aos="zoom-in" 
                                    //         // style={{ backgroundImage: `url(${localStorage.getItem('bucketName')}/${innerArray.main_image})`}}
                                    //         style={{ backgroundImage: `url(${pBanner[outerIndex]})`}}

                                    //         >
                                    //             {/* <img className="card-img" src={localStorage.getItem('bucketName') + '/' + innerArray.main_image} alt="Card image" /> */}
                                    //              <img className="p-3" src={pLogo[outerIndex]} alt="Card image" width='150px' /> 
                                    //             <div className="card-img-overlay d-flex flex-column justify-content-between">
                                    //                 <h5><span className="badge bg-white text-black float-end p-2">{innerArray.country_name}</span></h5>
                                    //                 <div className="flex-end bg-black p-2 rounded" styles="--bs-bg-opacity: .80;">
                                    //                     <h5 className=" mb-0 pb-0">{innerArray.name}</h5>
                                    //                     <small className="card-text small ">{innerArray.count} members</small>
                                    //                 </div>
                                    //             </div>
                                    //         </Card>
                                    //     </Link>
                                    // // </Col>

                                    <div key={outerIndex} className="col-12 col-sm-4 mb-4">
                                        <Link to={`/about_pavilion/${innerArray.slug}`}>
                                            <Card className='bg-dark' >
                                                <Card.Img variant="top" src={bucketName + '/' + innerArray.main_image} />
                                                <Card.ImgOverlay >
                                                    <h5><span className="badge bg-primary float-end">{innerArray.country_name}</span></h5>
                                                </Card.ImgOverlay>
                                                <Card.Body  className='text-light'>
                                                    <Card.Title className='fs-6 text-light fw-bold'>
                                                          {innerArray.name}  
                                                    </Card.Title>
                                                    <Card.Text >
                                                        <medium>{innerArray.count} members</medium>
                                                    </Card.Text>
                                                    {/* <Button variant="primary">Go somewhere</Button> */}
                                                </Card.Body>
                                            </Card>
                                            {/* <Card className="bg-dark text-white border-0" data-aos='zoom-in'>
                                                <Card.Img className='rounded-top' src={bucketName + '/' + innerArray.main_image} alt="Card image" />
                                                <Card.ImgOverlay >
                                                    <h5><span className="badge bg-primary float-end">{innerArray.country_name}</span></h5>

                                                </Card.ImgOverlay>
                                                <Card.Body>
                                                    <Card.Title className='text-light'>
                                                        <h5>{innerArray.name}</h5>
                                                    </Card.Title>
                                                    <Card.Text>
                                                        <medium>{innerArray.count} members</medium>
                                                    </Card.Text>
                                                    <div className="flex-end bg-black p-2 rounded " styles="--bs-bg-opacity: .80;">
                                                    <h5 className=" mb-0 pb-0"></h5>
                                                    <small className="card-text small "></small>
                                                </div> 
                                                </Card.Body>
                                            </Card> */}
                                        </Link>
                                    </div>

                                ))}
                            </Row>
                        )}
                        {/* <div className="col-12 col-sm-4 mb-4">
                <div className="card  text-white border-0" data-aos="zoom-in">
                    <img className="card-img" src="https://staging.bicpavilion.com/images/pavilion/45111660311736.png"
                        alt="Card image"/>
                    <div className="card-img-overlay d-flex flex-column justify-content-between">
                        <h5><span className="badge bg-primary float-end">India</span></h5>
                        <div className="flex-end bg-black p-2 rounded " styles="--bs-bg-opacity: .80;">
                            <h5 className=" mb-0 pb-0">Pavilion Blockchain</h5>
                            <small className="card-text small ">0 members</small>
                        </div>
                    </div>
                </div>
            </div> */}

                        {/* <div className="col-12 col-sm-4 mb-4">
                <div className="card  text-white border-0" data-aos="zoom-in">
                    <img className="card-img" src="https://staging.bicpavilion.com/images/pavilion/81791603469156.png"
                        alt="Card image"/>
                    <div className="card-img-overlay d-flex flex-column justify-content-between">
                        <h5><span className="badge bg-primary float-end">United Kingdom</span></h5>
                        <div className="flex-end bg-black p-2 rounded " styles="--bs-bg-opacity: .80;">
                            <h5 className=" mb-0 pb-0">The Innovation Board</h5>
                            <small className="card-text small ">0 members</small>
                        </div>
                    </div>
                </div>
            </div> */}

                        {/* <div className="col-12 col-sm-4 mb-4">
                <div className="card  text-white border-0" data-aos="zoom-in">
                    <img className="card-img" src="https://staging.bicpavilion.com/images/pavilion/96551603468541.png"
                        alt="Card image"/>
                    <div className="card-img-overlay d-flex flex-column justify-content-between">
                        <h5><span className="badge bg-primary float-end">India</span></h5>
                        <div className="flex-end bg-black p-2 rounded " styles="--bs-bg-opacity: .80;">
                            <h5 className=" mb-0 pb-0">APPG Blockchain</h5>
                            <small className="card-text small ">0 members</small>
                        </div>
                    </div>
                </div>
            </div> */}

                        {/* <div className="col-12 col-sm-4 mb-4">
                <div className="card  text-white border-0" data-aos="zoom-in">
                    <img className="card-img" src="https://staging.bicpavilion.com/images/pavilion/14831603467852.png"
                        alt="Card image"/>
                    <div className="card-img-overlay d-flex flex-column justify-content-between">
                        <h5><span className="badge bg-primary float-end">South Africa</span></h5>
                        <div className="flex-end bg-black p-2 rounded " styles="--bs-bg-opacity: .80;">
                            <h5 className=" mb-0 pb-0">APPG Artificial Intelligence</h5>
                            <small className="card-text small ">320 members</small>
                        </div>
                    </div>
                </div>
            </div> */}

                        {/* <div className="col-12 col-sm-4 mb-4">
                <div className="card  text-white border-0" data-aos="zoom-in">
                    <img className="card-img" src="https://staging.bicpavilion.com/images/pavilion/17281660203203.png"
                        alt="Card image"/>
                    <div className="card-img-overlay d-flex flex-column justify-content-between">
                        <h5><span className="badge bg-primary float-end">Pakistan</span></h5>
                        <div className="flex-end bg-black p-2 rounded " styles="--bs-bg-opacity: .80;">
                            <h5 className=" mb-0 pb-0">Pavilion Bitcoin</h5>
                            <small className="card-text small ">0 members</small>
                        </div>
                    </div>
                </div>
            </div> */}
                    </CardGroup>
                    {/* </Row> */}
                </Container>
            </>
            </main>
        </div>
    )
}

export default HomeContent;