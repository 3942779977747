import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import PRFLPavilionSetup from './PRFLPavilionSetup';
import http from '../../../../http';



const AntTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-flexContainer': {
        border: '1px solid #ececec',
        width: 'fit-content',
        padding: '2px 3px',
        borderRadius: 50,
    },
});






interface StyledTabProps {
    label: string;
}
const AntTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    minHeight: 20,
    borderRadius: 50,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 3,
    paddingBottom: 3,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: ['Barlow'].join(','),
    '&:hover': {
        color: '#e30613',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: 'white',
        backgroundColor: '#e30613',
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));

function PRFLBusinessServices({ handleClick }) {
    const [showBS, setShowBS] = useState(true);
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const pavilionTabs = "Basic Information - Pavilion Info - Pavilion Category - Pavilion Graphics - Bank Details - Make Payment - Congrats"
    const [membership, setMembership] = useState(['Standard', 'Premium'])
    const [mprice, setMPrice] = useState(['£250', '£500'])
    const [yprice, setYPrice] = useState(['£2750', '£5500'])
    const [mPoints, setMPoints] = useState(['£2750', '£5500'])
    const [membershipImages, setMembershipImages] = useState([
        localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/87261700224799.png",
        localStorage.getItem('bucketName')+"/images/pavilion/thumbnails/77841700224683.png"
    ]);
    const [btnText, setBtnText] = useState(["Current", "Know More"]);
    
    const handleState = () => {
        setShowBS(true);
     }

     const [planName, setPlanName] = useState();
     const [planType, setPlanType] = useState();
     const [planAmount, setPlanAmount] = useState();
     const [planId,setPlanId]=useState();
     
     const handleBuyNow = (planId,pName,pType,pAmount) => {
        setPlanId(planId);
        setPlanName(pName);
        setPlanType(pType);
        setPlanAmount(pAmount);
        setShowBS(false);
     }
  const [bucketName, setBucketName] = useState([]);
  const [annuallyName, setAnnuallyName] = useState([]);
  const [monthlyName, setMonthlyName] = useState([]);

	 
  useEffect(() => {
    http().get('/s3bucketname').then((data) => {
      setBucketName(data.data.data.bucketName);
    });
	http().get('/plans').then((data) => {
    //   console.log(data);
    //   console.log(data.data.data.plans);
    //   console.log(data.data.data.plans.annually);
    //   console.log(data.data.data.plans.monthly);
	  setAnnuallyName(data.data.data.plans.annually);
      setMonthlyName(data.data.data.plans.monthly);
     // console.log(data);
    });
	
	
  }, []);


    return (
        <>
        {showBS ? (
        <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item variant='light' className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                <div>
                    {/* <IconButton onClick={event => handleClick(false, true,'PVLN2')}><ArrowBackIcon /></IconButton> */}
                    <Typography variant="small">Business Services</Typography>
                </div>
                <IconButton onClick={event => handleClick('PRFL00')}><Home /></IconButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100 px-0  border-0 bg-light bg-opacity-75">
                <div className="container slim-scrollbar mh-70  py-0 ">
                    <TabContext value={value}>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }} className=" bg-transparent rounded-4 p-0 small d-flex flex-column justify-content-between align-items-center">
                            <AntTabs
                                className=''
                                value={value}
                                onChange={handleChange}
                                scrollButtons={false}
                                aria-label="scrollable auto tabs example"
                            >
                                <AntTab label={<Typography variant="h6" className="text-capitalize py-0">Monthly</Typography>} value="1" />
                                <AntTab label={<Typography variant="h6" className="text-capitalize py-0">Yearly</Typography>} value="2" />
                            </AntTabs>
                            <TabPanel value="1" className="d-flex align-items-start justify-content-center m-0 p-0 ">
                                <Box sx={{ width: '80%', typography: 'body1', mx: 1 }}>
                                    <Row xs={1} md={1} className="gy-3 " >
										{monthlyName.map((innerArray, outerIndex) => (
                                            <Col key={outerIndex} className="p-0">
                                                <Card className="mb-0 shadow-sm bg-light rounded-3" style={{ cursor: "pointer" }}>    <Card.Body className="py-2 px-0 bg-body  rounded-3 d-flex flex-column align-items-center justify-content-between " style={{ minHeight: "75px" }}>

                                                        <Card.Title className="pt-0 my-2" style={{ lineHeight: "0.7" }}>
                                                            <Typography key={outerIndex} variant="large" className="text-primary" >
                                                                <h4>{innerArray.name}</h4>
                                                            </Typography>
                                                        </Card.Title>
                                                        <Card.Text className="mb-1 px-2">
                                                            <Typography key={outerIndex} variant="small" className="small text-muted">
								<p dangerouslySetInnerHTML={{ __html: innerArray.description }}></p>															   
                                                            </Typography>
                                                        </Card.Text >

                                                        <Card.Text className="w-100 px-2 d-flex flex-row justify-content-between align-items-center">
                                                            <Typography  variant="small" className="h2 text-muted"> {innerArray.price}</Typography>
                                                            <Button key={outerIndex} onClick={event => handleBuyNow(innerArray.id, innerArray.name,'Monthly',innerArray.price)} variant="primary" size="sm">BUY NOW</Button>
                                                        </Card.Text>


                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </Box>
                            </TabPanel>
                            <TabPanel value="2" className='d-flex align-items-start justify-content-center m-0 p-0 '>
                                <Box sx={{ width: '80%', typography: 'body1', mx: 1 }}>
                                    <Row xs={1} md={1} className="gy-3 " >
										{annuallyName.map((innerArray, outerIndex) => (									
                                            <Col key={outerIndex} className="p-0">
                                                <Card className="mb-0 shadow-sm bg-light rounded-3" style={{ cursor: "pointer" }}>
                                                    <Card.Body className="py-2 px-0 bg-body  rounded-3 d-flex flex-column align-items-center justify-content-between " style={{ minHeight: "75px" }}>
                                                        <Card.Title className="pt-0 my-2" style={{ lineHeight: "0.7" }}>
                                                            <Typography key={outerIndex} variant="large" className="text-primary" >
                                                                <h4>{innerArray.name}</h4>
                                                            </Typography>
                                                        </Card.Title>
                                                        <Card.Text className="mb-1 px-2">
                                                            <Typography key={outerIndex} variant="small" className="small text-muted">
                            	<p dangerouslySetInnerHTML={{ __html: innerArray.description }}></p>   

                                                            </Typography>
                                                        </Card.Text >
                                                        <Card.Text  className="w-100 px-2 d-flex flex-row justify-content-between align-items-center">
                                                            <Typography variant="small" className="h2 text-muted">{innerArray.price}</Typography>
                                                            <Button key={outerIndex} onClick={event => handleBuyNow(innerArray.id, innerArray.name,'Yearly',innerArray.price)} variant="primary" size="sm">BUY NOW</Button>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </Box>
                            </TabPanel>
                        </Box>
                    </TabContext>
                </div>
            </ListGroup.Item>
        </ListGroup>
        ) : (<PRFLPavilionSetup change = {handleState} planName={planName} planId={planId} planType={planType} planAmount={planAmount} handleClick={handleClick}/> )}
        </>
    )
}

export default PRFLBusinessServices
