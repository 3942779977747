import React, { useEffect, useState } from 'react'
import InfiniteScroll from "react-infinite-scroller";
import ListGroup from 'react-bootstrap/ListGroup';
import { Image, Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import BadgeAvatar from '../../../BadgeAvatar';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MembersFilter from '../../Filters/MembersFilter';
import axios from "axios";
import http from '../../../../http';

function PVLNMembers(props,{ handleClick }) {

  const [hasMore, setHasMore] = useState(true);
  const [albums, setAlbums] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMsg, setLoadMsg] = useState('Loading...');
  
const [userdetails, setUserdetails] = useState([]);
const [post, setPost] = useState([]);
const [category, setCategory] = useState([]);
const libraryImagePath = localStorage.getItem('bucketName')+'/images/';
const libraryImagePathStatic = localStorage.getItem('bucketName')+'/images/noimage.webp';
const [memberList,setMemberList]=useState(true);

const [countrylist, setCountrylist] = useState([]);
const [industriesSectors, setIndustriesSectors] = useState([]);
const [areaOfInterests, setAreaOfInterests] = useState([]);
const [ecosystemnameArray, setEcosystemnameArray] = useState([]);

const [byName, setByName] = useState('');
const [byOrg, setByOrg] = useState('');
const [name_contains, setNameContains] = useState('');
const [roleFilter, setRoleFilter] = useState('');
const [countryFilter, setCountryFilter] = useState('');
const [ecosystemFilter, setEcosystemFilter] = useState('');
const [industriesFilter, setIndustriesFilter] = useState('');
const [areaOfInterestFilter, setAreaOfInterestFilter] = useState('');
const [btnText, setBtnText] = useState("Follow")
const [permissions, setPermissions] = useState({});
const [activeMember, setActiveMember]=useState(0);


const handleByNameChange = (e) => {
    setByName(e.target.value);
};	
const handleByOrgChange = (e) => {
    setByOrg(e.target.value);
};
const handleNameContainsChange = (e) => {
    setNameContains(e.target.value);
};
 const handleRoleFilterChange = (e) => {
    setRoleFilter(e.target.value);
};
 const handleCountryFilterChange = (e) => {
    setCountryFilter(e.target.value);
};  
 const handleEcosystemFilterChange = (e) => {
    setEcosystemFilter(e.target.value);
};
 const handleIndustriesFilterChange = (e) => {
    setIndustriesFilter(e.target.value);
};
 const handleAreaOfInterestFilterChange = (e) => {
    setAreaOfInterestFilter(e.target.value);
};
 
 const handleShowFilter = () => {
	setShowFilter(1);
    setByName('');
	setByOrg('');
	setNameContains('');
	setRoleFilter('');
	setCountryFilter('');
	setEcosystemFilter('');
	setIndustriesFilter('');
	setAreaOfInterestFilter('');
};
  
  
const handleMemberSearch = () => {
	setShowFilter(0);
    console.log(byName);
    console.log(byOrg);	
	http().post('/membersearch/'+props.idx, {
			pavilionid:props.idx,
			name: byName,
			name_contains:name_contains,
			organization:byOrg,
			ecosystem:ecosystemFilter,
			country_id:countryFilter,
			describe_id:industriesFilter,
			intrests_area:areaOfInterestFilter,
			role_id:roleFilter			
	}).then((data) => {
			console.log(data);
			setUserdetails(data.data.data.all_users.data);
      setPermissions(data.data.data.permission);
			setMemberList(true);
	});
  };

const handleClickRole = (variable) => () => {
	 http().get('/getPavilionMemberroles/'+props.idx+'/'+variable).then((data) => {
							setUserdetails(data.data.data.all_users.data);
							setMemberList(true);
              setPermissions(data.data.data.permission);
						});
  };
  
  useEffect(() => {
   
	  http().get('/roles/'+props.idx).then((data) => {
		    for (let i = 0; i < data.data.data.roles.length; i++) {
				if (data.data.data.slug === `who's-who` || data.data.data.slug === `who%27s-who`) {
            if(data.data.data.roles[i].name=='member' || data.data.data.roles[i].name=='Member'){
              http().get('/getPavilionMemberroles/'+props.idx+'/'+data.data.data.roles[i].id).then((data) => {
                 //setUserdetails(data.data.data.all_users.data);
                         setUserdetails((prevItems) => [...prevItems, ...data.data.data.all_users.data]);
                   setPermissions(data.data.data.permission);
                 setMemberList(true);
                 document.getElementById('left-tabs-example-tab-'+i).click();
              });
           }
          }else{
            if(data.data.data.roles[i].name=='Co-Chairs'){
              http().get('/getPavilionMemberroles/'+props.idx+'/'+data.data.data.roles[i].id).then((data) => {
                 //setUserdetails(data.data.data.all_users.data);
                         setUserdetails((prevItems) => [...prevItems, ...data.data.data.all_users.data]);
                   setPermissions(data.data.data.permission);
                 setMemberList(true);
               });
           }
           
          }
			}
      setPost(data.data.data.roles);

    });
  }, []);
  
  useEffect(() => {

		http().get('/memberFilterNames/'+props.idx).then((data) => {
		  setCountrylist(data.data.data.countries);
		  setIndustriesSectors(data.data.data.industriesSectors);
		  setAreaOfInterests(data.data.data.areaOfInterests);
		  setEcosystemnameArray(data.data.data.ecosystemnameArray);
		});
  }, []);
  




  /*const [memberName, setMemberName] = useState(["Thirath Virdee", "Alex Welr", "Alan Cook", "James Krik", "Mohsin Khan", "Ijaz Ali", "Raju Dayyala"]);
  const [memberDesig, setMemberDesig] = useState(["Co-Founder and Chief Technology Officer", "Co-Founder/Chairman", "Principal Consultant - Blockchain", "Founder", "Manager", "Developer", "Programmer"]);
  const [memberCo, setMemberCo] = useState(["DRE Digital Limited", "IPerfectAI Ltd", "Freelance", "Ditto", "BIC", "BIC", "BIC"]);*/
  
	const [showFilter,setShowFilter] = useState(0);
    const [val,setVal]=useState([]);

   const handleFollowClick=(i)=>{
	var button = document.getElementById('user_'+i);
	http().post('/followorunfollow', {
		to_id:i,	
	}).then((data) => {
	});
    if (button.textContent=='Follow') {
      button.textContent = 'Unfollow';	
    }else{
      button.textContent = 'Follow';
	}
   }
   



  return (
    <>
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
      <ListGroup.Item   className='d-flex justify-content-between align-items-center list-group-item-light border-0 text-center py-0'>
        <div>
          <IconButton onClick={event => props.handleClick('PVLN-MENU',props.idx)} className='btn btn-outline-mode p-1 m-1'><ArrowBackIcon /></IconButton>
          <Typography variant="small">Members</Typography>
        </div>
        <IconButton className='btn btn-outline-mode p-1 m-1' onClick={event => props.handleClick('PVLN-HOME',props.idx)}><Home /></IconButton>
        <IconButton className='btn btn-outline-mode p-1 m-1' onClick={handleShowFilter} ><FilterAltIcon /></IconButton>
      </ListGroup.Item>
      {showFilter!==1 && (
      <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
        <div className="container   py-0 ">
          <Tab.Container id="left-tabs-example"  defaultActiveKey={activeMember} 
          // onSelect={(eKey)=>alert(eKey)}
          >
            <Row>
            <Col sm={12}>
                <Nav variant="pills" className="flex-row small align-items-center justify-content-center"  >
			                {post.map((innerArray, outerIndex) => (
                  <Nav.Item >
				             <Nav.Link eventKey={outerIndex}  className='px-2 py-1 rounded rounded-5' onClick={handleClickRole(innerArray.id)}>{innerArray.name}</Nav.Link>
                  </Nav.Item>
			        ))}
                              </Nav>
              </Col>
            </Row>
            {memberList && (<Row>
              <Col sm={12} className='px-0'>
                <Tab.Content>
                {post.map((innerArray, outerIndex) => (
                  <Tab.Pane eventKey={outerIndex}>
                    <Row xs={1} md={12} className="g-3 d-flex justify-content-between align-items-center slim-scrollbar mt-2" style={{ maxHeight: "55vh" }} >
					            {userdetails.map((innerArray, outerIndex) => (			
                        <>
                          <Col key={innerArray.user_id} md={2} className="">
                          <a target="_blank" href={ "/" + innerArray.my_link}>
                            <>
                              {innerArray.image? <BadgeAvatar  src={localStorage.getItem('bucketName')+'/images/profile_picture/'+innerArray.image} name="Stephen Metcalfe" size='40' />:<BadgeAvatar  name={innerArray.name} size='40' />}
                            </>
                          </a>
                          </Col>
                          <Col md={7}>
                          <a target="_blank" href={ "/" + innerArray.my_link}><>
                            <h6 className='mb-0'>{innerArray.name}</h6></></a>
                            <small>{innerArray.designation !=="" &&innerArray.designation && innerArray.designation.substring(0, 36)}</small><br />
                            <small>{innerArray.organization !=="" &&innerArray.organization && innerArray.organization.substring(0, 36)}</small>
                          </Col>
                          <Col md={3} value={innerArray.user_id} className='text-end'>
                          {permissions['follow-member'] && 
                            <Button variant="primary" size="sm" className='small' id={'user_'+innerArray.user_id} 
							                  onClick={() => handleFollowClick(innerArray.user_id)}
							                  >{innerArray.is_following===0? 'Follow':'Unfollow'}</Button>
                          }
                          </Col>
                        </>
                      ))}
                    </Row>
                  </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>)}
          </Tab.Container>
        </div>
      </ListGroup.Item>
      )}

      {showFilter===1 && (
        <ListGroup.Item className=" mx-0 px-0 d-flex align-items-start justify-content-center h-100 border-0 bg-light bg-opacity-75">
        <Container>
            <Form className="mx-0 px-0 text-center">
                <Form.Control size="sm" name="byName" type="text" placeholder="By Name" className="my-1 py-1"  value={byName}  onChange={handleByNameChange} />
                <Form.Control size="sm" name="byOrg" type="text" placeholder="By Organisations" className="my-1 py-1" value={byOrg}  onChange={handleByOrgChange}  />
                <Form.Select required size="sm" id="name_contains" name="name_contains" className="my-1 py-1"   onChange={handleNameContainsChange}>
                    <option value="" selected >Name Starts/Ends/Contains</option>
                    <option value="start" >Name Starts with</option>
                    <option value="end" >Name Ends with</option>
                    <option value="contain" >Name Contains</option>
                </Form.Select>
                <Form.Select name="All Roles" size="sm" className="my-1 py-1" onChange={handleRoleFilterChange}>
                    <option value="" selected >All Roles</option>
					{post.map((innerArray, outerIndex) => (
					<option value={innerArray.id} >{innerArray.name}</option>
					))}
                </Form.Select>
                <Form.Select size="sm" id="country_id" name="country_id" className="my-1 py-1"  onChange={handleCountryFilterChange}>
                    <option value="" selected >Country</option>
					{countrylist.map((countrylistArray, countrylistIndex) => (
					<option value={countrylistArray.id}>{countrylistArray.name}</option>
					))}
                </Form.Select>
                <Form.Select name="ecosystem" id="ecosystem" size="sm" className="my-1 py-1" onChange={handleEcosystemFilterChange}>
					<option value="">Select Ecosystem</option>
					{ecosystemnameArray.map((ecosystemnameArray, ecosystemnameArrayIndex) => (
					<option value={ecosystemnameArray.name}>{ecosystemnameArray.value}</option>
					))}
                </Form.Select>
                <Form.Select required id="describe_id" size="sm" name="describe_id" className="my-1 py-1"  onChange={handleIndustriesFilterChange}>
                    <option value="" selected >All Industries</option>
                    {industriesSectors.map((industriesSectors, industriesSectorsIndex) => (
					<option value={industriesSectors.id}>{industriesSectors.name}</option>
					))}
                </Form.Select>
                <Form.Select size="sm" name="intrests_area" id="area-of-interest" className="my-1 py-1"  onChange={handleAreaOfInterestFilterChange}>
                    <option  value="" selected >Area of Interest</option>
                    {areaOfInterests.map((areaOfInterests, areaOfInterestsIndex) => (
					<option value={areaOfInterests}>{areaOfInterests}</option>
					))}
                </Form.Select>

                <Button size="sm" onClick={handleMemberSearch} >Search</Button>
            </Form>
        </Container>
    </ListGroup.Item>
      )}
    </ListGroup>
    </>
  )}
export default PVLNMembers
