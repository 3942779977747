import React, { useState, useEffect } from 'react'
import InfiniteScroll from "react-infinite-scroller";
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined, ContentCopy, Facebook } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import BadgeAvatar from '../../../BadgeAvatar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Like from '@mui/icons-material/FavoriteBorder';
import Comment from '@mui/icons-material/MessageOutlined';
import Share from '@mui/icons-material/ReplyOutlined';
import Flag from '@mui/icons-material/OutlinedFlag';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import TelegramIcon from '@mui/icons-material/Telegram';
import Collapse from 'react-bootstrap/Collapse';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { CKEditor, ImageInsert } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
/*import Comments from '../../../../components/Comments/Comments2';*/
import CRUDDropdown from '../../../CRUDDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation } from 'react-router-dom';
import http, { baseUrl } from '../../../../http';
import axios from "axios";
import PreLoader from '../../../PreLoader';
import { PLSIZE, formatDate } from '../../../PavilionConstants';
import Swal from 'sweetalert2'
import Comments from '../../../Comments';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';

function PVLNWallPosts(props, { handleClick }) {
  const [progress, setProgress] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE} />);
  const [permissions, setPermissions] = useState({});
  const [open, setOpen] = useState({});
  const [open3, setOpen3] = useState(false);


  const [bucketName, setBucketName] = useState([]);
  const [post, setPostsValues] = useState([]);
  const [editorData, setEditorData] = useState('');
  const [pavilionSlug, setpavilionSlug] = useState('');
  const [pavilionWallData, setpavilionWallData] = useState('');
  const [writeData, setWriteData] = useState('Post');
  const [domainName, setDomain] = useState('');

  const [publicPost, setPublicPost] = useState([]);

  const [showPosts, setShowPosts] = useState(0);

  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  const [datatext, setdatatext] = useState('');
  const [dataurl, setdataurl] = useState('');


    const socialPopUp = (dataurl) => {
      const width = 550;
      const height = 420;
      const left = (window.innerWidth / 2) - (width / 2);
      const top = (window.innerHeight / 2) - (height / 2);
      window.open(
        dataurl,
        'twitter-share-dialog',
        `width=${width},height=${height},left=${left},top=${top}`
      );
    };
    const handleCopyUrl = (dataurl) => {
      navigator.clipboard.writeText( dataurl).then(() => {
        Swal.fire({
          title: "Text copied to clipboard!",
          text : dataurl,
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    };

  const handleCommentsClick = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };
  const handleCommentsLikeClick = (id) => {
    http().post('/likes/store', {
      type: "post",
      post_id: id,
    }).then((data) => {
      // console.log(data);
      http().get('/singlewallpost/' + props.idx + '/' + id + '/post').then((data) => {
        // console.log(data.data.data.post.likes.length);
        document.getElementById('postlikecount' + id).innerHTML = data.data.data.post.likes.length;
      });
    });
  };

  useEffect(() => {
    if (splitURL[1] === 'walls') {
      if (splitURL[2]) {
        http().get(`/walls/${splitURL[2]}`).then((data) => {
          setPublicPost([...publicPost, data.data.data.posts])
        });
        setShowPosts(1);
        // console.log(`The current route is ${splitURL[1]}`);        
      }
    }

  }, []);

  function handleDropdown(event) {
    event.stopPropagation();
    // console.log('handleChildClick');
  }


  const handleClickDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete this Post?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Post Deleting!",
          html: "Deleting Post, Please wait...",
          timerProgressBar: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          iconColor: '#e30613',
          didOpen: () => {
            Swal.showLoading();
            http().post('/delete_resource', {
              id: id,
              type: "post",
            }).then((data) => {
              Swal.fire({
                title: "Post is deleted successfully",
                icon: "success",
                allowOutsideClick: false,
                confirmButtonColor: "#e30613",
              });
              http().get('/wallfeed/' + props.idx).then((data) => {
                setPostsValues(data.data.data.posts.data);
                setDomain(data.data.data.domain);
                setPermissions(data.data.data.permission);
                console.log('ijaz');
                console.log(data.data.data.permission)
              });
              http().get('/about/' + props.idx).then((data) => {
                setpavilionSlug(data.data.data.aboutPavilion.slug);
              });
            });
          },
          willClose: () => {
            //clearInterval(timerInterval);
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
          }
        });
      
      }
    });
  }
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      http().get('/s3bucketname').then((data) => {
        setBucketName(data.data.data.bucketName);
      });
      http().get('/about/' + props.idx).then((data) => {
        setpavilionSlug(data.data.data.aboutPavilion.slug);
      });
    }
  }, []);


  function uploadAdapter(loader: FileLoader): UploadAdapter {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file;
            const response = await axios.request({
              method: "POST",
              url: `${baseUrl}/postattachment`,
              data: {
                upload: file,
                wall_action: 'create',
                wall_id: '',
              },
              headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${token}`
              }
            });
            resolve({
              // console.log(response);
              // console.log(response);
              // response.url
              default: `${response.data.data.url}`
            });
          } catch (error) {
            reject("Hello");
          }
        });
      },
      abort: () => { }
    };
  }

  function uploadPlugin(editor: Editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }


  const handleWallSubmit = (e) => {
    setProgress(true);
    http().post('/postText', {
      pavilion_id: props.idx,
      pavilion_name: pavilionSlug,
      wall_action: 'create',
      post_text: editorData,
    }).then((data) => {
      //console.log(data);
      setEditorData('');
      setWriteData('Post');
      Swal.fire({
        title: "Your Post submitted successfully!",
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      });
      setProgress(false);
      http().get('/wallfeed/' + props.idx).then((data) => {
        // console.log(data.data);
        // console.log(data.data.data.posts);
        setPostsValues(data.data.data.posts.data);
        setDomain(data.data.data.domain);

      });

    });
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    //alert('ssss');
    //console.log({ event, editor, data });
  };
  const handleEdit = (id, editor) => {
    setWriteData('Update');
    // console.log('handleEdit');
    //const data = editor.getData();
    //setEditorData(data);
    //const data = editor.getData();
    //setEditorData(data);
    //alert('vvvv'+id);
    http().get('/singlewallpost/' + props.idx + '/' + id + '/post').then((data) => {
      //setpavilionSlug(data.data.data.aboutPavilion.slug);
      //setpavilionWallData(data.data.data.post.post_content);
      //setEditorData(data.data.data.post.post_content);
	  setEditorData(data.data.data.post.post_content.replace(domainName + 'posts_attachments', bucketName + '/posts_attachments'));
    });

  }
  //Infinite Scroll
  //`${baseUrl}/events/${props.idx}?page=${page}`, {  
  const fetchData = async (__page) => {
    // console.log(__page);
    if (token) {
      const response = await
        http().get(`/wallfeed/${props.idx}?page=${page}`).then((response) => {
          setHasMore(true)
          // console.log(response.data.data.posts.data)
          setPostsValues([...post, ...response.data.data.posts.data]);
          setDomain(response.data.data.domain);
          setPermissions(response.data.data.permission);
          const last_page = response.data.data.posts.last_page;
          if (page < last_page) {
            setPage(page + 1);
            setHasMore(true)
            setLoadMsg(<PreLoader size={PLSIZE} />)
          } else {
            setHasMore(false)
            setLoadMsg('No More Records...')
          }
        })
    }
  };

  function membershipRequired(){
    Swal.fire({
        title: 'Membership Required!',
        type: 'warning',
        text: 'Please visit shop to purchase membership first.',
        showCancelButton: true,
        confirmButtonColor: '#e11e08',
        confirmButtonText: 'Visit Shop',
    }).then((result) => {
      if(result.isConfirmed){
        //window.location = BASE_URL + '/shop/' + '<?= encode_url($pavilion->name) ?>';
        props.handleClick('PVLN-SHOPP',props.idx)
      }
    });
}
  return (
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
      <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
        <div>
          <IconButton className='p-1 m-1 btn btn-outline-mode' sx={{ padding: 1 }} onClick={event => props.handleClick('PVLN-MENU', props.idx)}><ArrowBackIcon /></IconButton>
          <Typography variant="small">
            {showPosts === 0 && ('Wall Posts')}
            {showPosts === 1 && ('Public Wall Post')}
          </Typography>
        </div>
        <IconButton className='p-1 m-1 btn btn-outline-mode' sx={{ padding: 1 }} onClick={event => props.handleClick('PVLN-HOME', props.idx)}><Home /></IconButton>
        {/*<IconButton sx={{ padding: 1 }} ><PostAddIcon /></IconButton>*/}

      </ListGroup.Item>
      {showPosts === 0 && (
        <ListGroup.Item className="d-flex align-items-top justify-content-center px-2 h-100  border-0 bg-light bg-opacity-75">
          <div className="container slim-scrollbar mh-70  py-0 ">
          <ListGroup className="overflow-hidden px-0 mb-2 " style={{ borderRadius: "5px" }}>
          {!permissions['create-post'] && (
          <ListGroup.Item variant='light' className="py-2 fw-bold text-center">
            <h5>Join the Community to Make a Post</h5>
            <Button variant="primary" className="mt-1" size="sm" onClick={membershipRequired}>
              Upgrade
            </Button>
          </ListGroup.Item>
        )}

          {permissions['create-post'] && (<>
              <ListGroup.Item variant='light' className="py-2 fw-bold" >
                Write Post
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                <CKEditor
                  config={{
                    // @ts-ignore
                    extraPlugins: [uploadPlugin],
                    placeholder: 'Write something...'
                  }}
                  editor={ClassicEditor}
                  data={editorData}
                  onChange={handleEditorChange}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
                <Button variant="primary" disabled={progress} className="mt-2" size="sm" onClick={handleWallSubmit}>
                   {progress && (<Spinner size='sm' className="me-2" animation="border" />)} {writeData}
                  </Button>
              </ListGroup.Item>
              </>
            )}
</ListGroup>
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchData}
              hasMore={hasMore}
              initialLoad={true}
              // loader={
              //     <div className="loader" key={0}>
              //         {loadMsg}
              //     </div>
              // }
              useWindow={false}
            >
              {post.map((innerArray, outerIndex) => (
                <div>
                  <ListGroup className="overflow-hidden px-0 mb-2 " style={{ borderRadius: "5px" }}>
                    <ListGroup.Item variant='light' className='d-flex justify-content-between align-items-center  border-0 text-center py-1 px-1'>
                      <div className="w-100  d-flex justify-content-start align-items-center ">
                        <BadgeAvatar src={localStorage.getItem('bucketName') + "/images/profile_picture/" + innerArray.user.image} name={innerArray.user.name} size='30' isLoggedIn={false} />
                        <div className='ms-2 text-start '>
                          <h6 className='mb-0 lh-1'>{innerArray.user.name}</h6>
                          <small>{formatDate(innerArray.created_at)}</small>
                        </div>
                      </div>
                      {(permissions['edit-post'] || permissions['delete-post']) && (
                        <CRUDDropdown 
                          title={<MoreVertIcon />} 
                          handleDropdown={handleDropdown} 
                          editTitle={permissions['edit-post'] ? "Edit Post" : null}  
                          editClick={permissions['edit-post'] ? (event) => handleEdit(innerArray.id) : null}  
                          deleteTitle={permissions['delete-post'] ? "Delete Post" : null}  
                          deleteClick={permissions['delete-post'] ? (event) => handleClickDelete(innerArray.id) : null}  
                        />
                      )}


                      {/* <IconButton className='p-1' onClick={event => handleClick(true, false, 0)}><Home /></IconButton> */}
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex align-items-center justify-content-center h-100  border-0" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
                      <div className="container post-content px-0 py-1">
                        {/* <p className='lead' dangerouslySetInnerHTML={{ __html: innerArray.post_content.replace(domainName + 'posts_attachments', bucketName + '/posts_attachments') }}></p> */}
                        <div dangerouslySetInnerHTML={{__html: innerArray.post_content.replace(domainName + 'posts_attachments', bucketName + '/posts_attachments') }}></div>

                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="w-100 d-flex align-items-center justify-content-between h-100 p-0 border-0" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
                      <ButtonGroup size="sm" className=" w-100 rounded-bottom">
                        <Button size="sm" variant="outline-secondary" className="small" onClick={() => handleCommentsLikeClick(innerArray.id)} ><Like fontSize='small' />&nbsp; Like &nbsp;<Badge bg="primary" id={'postlikecount' + innerArray.id}>{innerArray.likes.length}</Badge></Button>
                        <Button size="sm" onClick={() => handleCommentsClick(innerArray.id)}
                          aria-controls="collapseComments3"
                          aria-expanded={open}
                          variant="outline-secondary" className='small'><Comment fontSize='small' /> Comment <Badge bg="primary" id={'postCommentcount' + innerArray.id}>{innerArray.all_comments.length > 0 && (innerArray.all_comments.length)}</Badge></Button>
                        {/* <Button size="sm" variant="outline-secondary" className="small"><Share fontSize='small' className="flipx" /> Share</Button> */}
                        <Dropdown as={Button} drop='up' variant="outline-secondary" className="p-0 m-0">
              <Dropdown.Toggle id="dropdown-basic" variant="" className="w-100 small d-flex justify-content-center align-items-center">
                <Share className='flipx' fontSize='small' /> &nbsp; Share &nbsp;<Badge bg="info">9</Badge>
              </Dropdown.Toggle>

              <Dropdown.Menu >
                <Dropdown.Item className='small'  onClick={()=>socialPopUp('https://telegram.me/share/url?url='+process.env.REACT_APP_SITE_URL+'/walls/'+innerArray.id)} ><TelegramIcon fontSize='small' /> Telegram</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>socialPopUp('https://www.facebook.com/sharer.php?u='+process.env.REACT_APP_SITE_URL+'/walls/'+innerArray.id)} ><FacebookIcon fontSize='small'/> Facebook</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>socialPopUp('https://twitter.com/share?url='+process.env.REACT_APP_SITE_URL+'/walls/'+innerArray.id)} ><TwitterIcon fontSize='small'/> Twitter</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>socialPopUp('https://www.linkedin.com/sharing/share-offsite/?url='+process.env.REACT_APP_SITE_URL+'/walls/'+innerArray.id)} ><LinkedInIcon fontSize='small'/> LinkedIn</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>handleCopyUrl(process.env.REACT_APP_SITE_URL+'/walls/'+innerArray.id)} ><ContentPasteIcon fontSize='small'/> Copy to Clipboard</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
                      </ButtonGroup>
                    </ListGroup.Item>
                    <Collapse key={outerIndex} in={open[innerArray.id]} className='p-1'>
                      <ListGroup.Item>
                        <div id="collapseComments3">
                          <Comments postId={innerArray.id} pavilionId={props.idx} type='post' />
                        </div>
                      </ListGroup.Item>
                    </Collapse>
                  </ListGroup>
                </div>
              ))}
            </InfiniteScroll>
            <div className="loader my-2 text-center" key={0} > {loadMsg} </div>


          </div>
        </ListGroup.Item>
      )}
      {showPosts === 1 && (
        <ListGroup.Item className="d-flex align-items-top justify-content-center px-2 h-100  border-0 bg-light bg-opacity-75">
          <div className='w-100'>
            <ListGroup className="overflow-hidden px-0 mb-2 " style={{ borderRadius: "5px" }}>
              {publicPost.map((innerArray, outerIndex) => (<>
                <ListGroup.Item key={outerIndex} variant='light' className='d-flex justify-content-between align-items-center  border-0 text-center py-1 px-1'>
                  <div className="w-100  d-flex justify-content-start align-items-center ">
                    <BadgeAvatar src={localStorage.getItem('bucketName') + "/images/profile_picture/23231686680942.png"} name="Charles Smith" size='20' isLoggedIn={false} />
                    <div className='ms-2 text-start '>
                      <h6 className='mb-0 lh-1'>{innerArray.user.name}</h6>
                      <small>{formatDate(innerArray.created_at)}</small>
                    </div>
                  </div>
                  {/* <CRUDDropdown title={<MoreVertIcon />} handleDropdown={handleDropdown} editTitle="Edit Post" editClick={(event) => handleEdit(innerArray.id)} deleteTitle="Delete Post"  deleteClick={(event) => handleClickDelete(innerArray.id)} /> */}
                  <IconButton className='p-1' onClick={event => handleClick(true, false, 0)}><Home /></IconButton>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex align-items-center justify-content-center h-100  border-0" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
                  <div className="container px-0   py-1">
                    <p className='lead' dangerouslySetInnerHTML={{ __html: innerArray.Data.replace(domainName + 'posts_attachments', bucketName + '/posts_attachments') }}></p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="w-100 d-flex align-items-center justify-content-between h-100 p-0 border-0" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
                  <ButtonGroup size="sm" className=" w-100 rounded-bottom">
                    <Button size="sm" variant="outline-secondary" className="small" ><Like /> Like</Button>
                    <Button size="sm" onClick={() => setOpen3(!open3)}
                      aria-controls="collapseComments3"
                      aria-expanded={open3}
                      variant="outline-secondary" className={`${open3 && 'bg-dark text-light'} small`}> <Comment />  Comment</Button>
                    <Button size="sm" variant="outline-secondary" className="small"><Share className="flipx" /> Share</Button>
                  </ButtonGroup>
                </ListGroup.Item>
                <Collapse key={3} in={open3} className='p-1'>
                  <ListGroup.Item>
                    <div id="collapseComments3">
                      {/*<Comments />*/}
                    </div>
                  </ListGroup.Item>
                </Collapse>
              </>
              ))}
            </ListGroup>
          </div>
        </ListGroup.Item>
      )}
    </ListGroup>
  )
}

export default PVLNWallPosts