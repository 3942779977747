import React, { useState } from 'react'
import {useNavigate } from 'react-router-dom'
import ApartmentIcon from '@mui/icons-material/Apartment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import Group from '@mui/icons-material/Group'
import http from '../http';
import Header101 from '../components/Header101';
import Footer101 from '../components/Footer101';
import { Row, Col, Container } from 'react-bootstrap';
import Swal from 'sweetalert2'

function ResetPwd() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const emailHandler = (event) => {
    setEmail(event.target.value)
  }
  function resetRequest() {
    http().post('/password/email', { email: email})
        .then((res) => {
          Swal.fire({
            title: "Reset password request sent to your email!",
            icon: "info",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });	
          //alert('Reset password request sent to your email.');
            navigate('/');
        })
        .catch((error) => {
            console.error('Error:', error);
            Swal.fire({
              title: "An error occurred while signing in. Please try again!",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });	
            //alert('An error occurred while signing in. Please try again.');
        });
  }
  const submitHandler = (event) => {
    event.preventDefault()
    resetRequest()
  }
  
  return (
    <div className="wrapper">
    <Header101/>
    <Container className="content-container content d-flex justify-content-center bg-register " >
    <div className="container col-xxl-8 px-0 pt-5">
      <div className="row  align-items-center g-5 pt-5">
    <div className="container col-lg-6 p-5 " data-aos="slide-right">
          <h3 className="fw-bold text-light lh-1 mb-3">Welcome to Pavilion 3.0 </h3>
          <p className="lead text-light">Share your professional interests, contribute and be connected with your community.</p>
          <form className="py-1 p-md-0 rounded-3 bg-transparent " onSubmit={submitHandler}>
            <div className="form-floating mb-1">
              <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={emailHandler}/>
              <label for="floatingInput">Email Address</label>
            </div>
            <div className="checkbox mb-3">
              <button className="btn btn-lg btn-secondary w-100" type="submit">RESET PASSWORD</button>
            </div>
          </form>
          <div className="container text-light">
            <p>Already have an account? <a href="/" className='btn btn-link btn-outline-light btn-sm'>Sign In</a></p>
          </div>
        </div>
        <div className="col-lg-6 px-5 " data-aos="slide-left">
          <div className="col-12 d-flex flex-column justify-content-center mx-5">
            <h1 className="m-4 text-light "><ApartmentIcon/> Companies</h1>
            <h1 className="m-4 text-light "><MenuBookIcon/> Academia</h1>
            <h1 className="m-4 text-light "><AssuredWorkloadIcon/> Government</h1>
            <h1 className="m-4 text-light "><Group/> Citizens</h1>
           </div>
        </div>
        </div></div>
      </Container>
      <Footer101/>
    </div>
  //   <div>

  //  <header id="myHeader" className="mb-0 mt-5 mt-sm-5 p-5 bg-register">
  //   <div className="container col-xxl-8 px-0 pt-5 ">
  //     <div className="row  align-items-center g-5 pt-5">
        
  //     </div>
  //   </div>
  //  </header>
  //   </div>
    
    
  )

}

export default ResetPwd