import React, { useState, useEffect, isValidElement } from 'react'
import { useNavigate } from 'react-router-dom';
import http from '../http';
import {Form, Button} from 'react-bootstrap';
import Swal from 'sweetalert2'

const ResetPwdForm = (props) => {
    const [validated, setValidated] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [conPassword, setConPassword] = useState('');

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [cPasswordClass, setCPasswordClass] = useState('form-control');
    const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);

    const [isClass, setIsClass] = useState('is-invalid');
    const [isClass2, setIsClass2] = useState('is-invalid');

    const result2=(oldPassword.trim().length>0 ? 'is-valid' : 'is-invalid')

    //const areEqual = ((newPassword.trim().length>0 && conPassword.trim().length>0) ? (newPassword===conPassword ? true : false) : false)
	
    // const result = areEqual ? "is-valid" : "is-invalid";
    //if(areEqual){setIsClass('is-valid')}else{setIsClass('is-invalid')}

    const handleOldPasswordChange =  (event) => {
        if(event.target.value.trim().length>0){
            if(event.target.value.trim().length<5){
                setIsClass2('is-invalid')
            } else {
                setIsClass2('is-valid')
            }
       } else {
            setIsClass2('is-invalid')  
       } 
        setOldPassword(event.target.value)
    }

    const handleNewPasswordChange =  (event) => {
           if(event.target.value.trim().length>0){
                if(event.target.value.trim().length<8){
                    setIsClass('is-invalid')
                } else {
                    if(conPassword===event.target.value){
                        setIsClass('is-valid')
                       }
                }
           } else {
                setIsClass('is-invalid')  
            } 
            setNewPassword(event.target.value)
    }


    const handleConPasswordChange =  (event) => {
           
        if(event.target.value.trim().length>0){
             if(event.target.value.trim().length<8){
                 setIsClass('is-invalid')
             } else {
                if(newPassword===event.target.value){
                 setIsClass('is-valid')
                }
             }

        } else {
             setIsClass('is-invalid')  
         } 
         setConPassword(event.target.value)
 }
     const handleSubmit =  (event) => {
        
        event.preventDefault();
        if(isClass==='is-valid' && isClass2==='is-valid'){

           http().post('/changepassword', { current_password: oldPassword, new_password: newPassword, new_confirm_password: conPassword}).then((res) => {
          
            Swal.fire({
            title: "Reset password successfully done!",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });	
          props.callbackModal();
        }).catch((error) => {
            console.error('Error:', error);
            Swal.fire({
              title: "An error occurred!",
              text: 'Either current password mismatched or new password is less than 8 characters, Please try again!',
              icon: "error",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });	
        });
    }
     };
  
  
    
  return (
       <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    
                    <Form.Group  className="mb-2">
                        <Form.Control className={isClass2} required type="password" id="oldPassword" value={oldPassword} onChange={handleOldPasswordChange}
                            placeholder="Current Password" />
                       </Form.Group>
                    <Form.Group  className="mb-2">
                        <Form.Control className={isClass} required type="password" id="newPassword" value={newPassword} onChange={handleNewPasswordChange}
                            placeholder="New Password" />
                            <Form.Control.Feedback type="invalid">
                                New Password length must be 8 characters or more
                            </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group  className="mb-2">
                        <Form.Control className={isClass} required type="password" id='conPassword' value={conPassword} onChange={handleConPasswordChange} placeholder="Confirm Password" />
                        <Form.Control.Feedback type="invalid">
                                Confirm Password must match with New Password
                            </Form.Control.Feedback>
                    </Form.Group>
                    
                    
                    <Button variant='primary' className="w-100" type="submit">RESET PASSWORD</Button>
                </Form>
  )
}

export default ResetPwdForm
