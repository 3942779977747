import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Hidden, SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import InputGroup from 'react-bootstrap/InputGroup';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import Select from 'react-select';
import { ECOSYSTEM_OPTIONS, COUNTRIES } from '../../../PavilionConstants';
import Accordion from 'react-bootstrap/Accordion';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2';
import http from '../../../../http';

const AntTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-flexContainer': {
    border: '1px solid #ececec',
    width: 'fit-content',
    padding: '2px 3px',
    borderRadius: 50,
  },
});

interface StyledTabProps {
  label: string;
}
const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  minHeight: 20,
  borderRadius: 50,
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 3,
  paddingBottom: 3,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: ['Barlow'].join(','),
  '&:hover': {
    color: '#e30613',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#e30613',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

function PRFLUpdateProfile({ handleClick }) {
  const [value, setValue] = useState('1');
  const [value2, setValue2] = useState('1');
  const [roles, setRoles] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [countries, setCountries] = useState([]);
  const [user_describe, setUserDescribe] = useState([]);
  const [ecosystem, setEcoSystem] = useState([]);
  const [areaofinterest, setAreaofinterest] = useState([]);
  const [cities, setCities] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [membershipsCount, setMembershipsCount]= useState([]);
  const [roleCount, setRoleCount]= useState([]);

  
  // Fetch countries and set them initially
  useEffect(() => {
    // Fetch countries logic here
    // Example:
    http().get('/getcountries')
      .then(response => {
        setCountries(response.data.data.countries);
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });

    // If there is a selected country, fetch the cities
    if (userData.country_id) {
      fetchCities(userData.country_id);
    }
  }, [userData.country_id]);

  const handleChangeMultiple = (selectedOptions) => {
    setSelectedOptions(selectedOptions); // Update the selected options in the state
  
    // Update userData state with ecosystem
    setUserData({
      ...userData,
      ecosystem: selectedOptions.map(option => option.value) // Store the selected ecosystem values in userData
    });
  };
  
  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  useEffect(() => {
    fetchProfileData();
    fetchUserData();
  }, []);
  const fetchProfileData = async () => {
    http().get('/profiledata').then((res) => {
      console.log(res.data.data);
      setCountries(res.data.data.countries);
      setUserDescribe(res.data.data.user_describes);
      setEcoSystem(res.data.data.ecosystem);
      setAreaofinterest(res.data.data.area_of_interest);
    });
  };
  const fetchUserData = async () => {
    try {
      const res = await http().get('/userdetails');
      const userData = res.data.data.user;
      
      // Log to check the API response for ecosystem
      console.log("API Response Ecosystem: ", userData.ecosystem);
      
      // Update userData state
      setUserData(userData);
  
      // Update selectedOptions state with matched ecosystem values
      const ecosystem = userData.ecosystem || []; // Ensure it's an array
      if (ecosystem.length > 0) {
        const matchedOptions = ECOSYSTEM_OPTIONS.filter(option =>
          ecosystem.includes(option.value)
        );
        setSelectedOptions(matchedOptions); // Update the selected options
      } else {
        setSelectedOptions([]); // If no ecosystem, clear the options
      }
  
      // Update other states as necessary
      setMemberships(res.data.data.memberships);
      setMembershipsCount(res.data.data.memberships.length);
      setRoles(res.data.data.roles);
      setRoleCount(res.data.data.roles.length);
      setLanguages(res.data.data.languages);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
  
      const dataToSend = {
        ...userData,
        country: userData.country_id,
        city: userData.city_id,
        describes: userData.describe_id,
        ecosystem: selectedOptions.map(option => option.value) // Send ecosystem values as IDs
      };
  
      // Remove unwanted fields
      delete dataToSend.country_id;
      delete dataToSend.city_id;
      delete dataToSend.describe_id;
  
      await http().post('/updateprofile', dataToSend);
  
      Swal.fire({
        title: 'User data updated successfully!',
        icon: 'success',
      });
  
      fetchUserData(); // Fetch updated data
      setLoading(false);
    } catch (error) {
      console.error('Error updating user data:', error);
      setLoading(false);
      Swal.fire({
        title: 'Failed to update user data!',
        icon: 'error',
      });
    }
  };
  

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });

    if (name === 'country_id') {
      fetchCities(value);
    }
  };

  const fetchCities = async (countryId) => {
    try {
      const response = await http().post('/getcitiesbycountry', { country_id: countryId });
      setCities(response.data.data.cities);
      // Reset city selection when country changes
      // Set city_id only if it's valid for the selected country
      setUserData((prevState) => ({
        ...prevState,
        city_id: response.data.data.cities.some(city => city.id === prevState.city_id) ? prevState.city_id : ''
      }));
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return (
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
      <ListGroup.Item variant='light' className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
        <Typography variant="small">Update Profile</Typography>
        <IconButton className='btn btn-outline-mode' onClick={event => handleClick('PRFL-MENU1')}><Home /></IconButton>
      </ListGroup.Item>
      <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75" >
        <div className="container  slim-scrollbar py-2 mh-70" >

          <TabContext value={value} >
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }} className=" bg-light rounded-4 p-0 small d-flex flex-column justify-content-between align-items-center">
              <AntTabs
                className=''
                value={value}
                onChange={handleChange1}
                scrollButtons
                aria-label="scrollable auto tabs example"
              >
                <AntTab label={<Typography variant="h6" className="text-capitalize py-0">About</Typography>} value="1" />
                <AntTab label={<Typography variant="h6" className="text-capitalize py-0">Membership Plan</Typography>} value="2" />
                <AntTab label={<Typography variant="h6" className="text-capitalize py-0">Role</Typography>} value="3" />
              </AntTabs>
              <TabPanel value="1" className="d-flex align-items-start justify-content-center m-0 p-0 ">
                <Box sx={{ width: '58%', typography: 'body1', mx: 2 }}>
                  <TabContext value={value2} >
                    <Box sx={{ m: -2, borderBottom: 1, overflowX: 'hidden', flexGrow: 1, borderColor: 'divider' }}>

                      <Tabs
                        value={value2}
                        onChange={handleChange2}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#e30613",
                            padding: "1",
                          }
                        }}
                        inkBarStyle={{ color: 'black' }}
                        variant="scrollable"
                        scrollButtons
                        aria-label="scrollable auto tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                          }, textTransform: 'capitalize', justifyContent: 'center',
                          "& button": { fontSize: '12px' },
                          p: 0,
                          "& .MuiTabs-indicator": {
                            backgroundColor: "#e30613",
                            height: 2,
                          },
                          "& .MuiTab-root.Mui-selected": {
                            color: '#e30613'
                          }
                        }}
                      >
                        <Tab sx={{ textTransform: 'capitalize', margin:0, padding: 0, minWidth:'70px' }} label="Basic Info" value="1" />
                        <Tab sx={{ textTransform: 'capitalize', margin:0,  padding: 0, minWidth:'60px'  }} label="My Bio" value="2" />
                        <Tab sx={{ textTransform: 'capitalize', margin:0,  padding: 0, width:'fit-content' }} label="Contact Info" value="3" />
                        <Tab sx={{ textTransform: 'capitalize', margin:0,  padding: 0, width:'fit-content' }} label="Work Details" value="5" />
                        <Tab sx={{ textTransform: 'capitalize',  margin:0, padding: 0, width:'fit-content' }} label="Social Media" value="4" />
                      </Tabs>
                    </Box>
                    <TabPanel value="1" sx={{ py: 3, px:0 }} style={{ textAlign: "center" }}>
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">

                          <Form.Select size="sm" aria-label="Default select example" className="my-2 py-1" name="surname" value={userData.surname} onChange={handleChange}>
                            <option selected>Title...</option>
                            <option value="Mr" >Mr.</option>
                            <option value="Ms">Ms.</option>
                            <option value="Dr">Dr.</option>
                            <option value="Prof">Prof.</option>
                            <option value="Eng">Eng.</option>
                          </Form.Select>

                          <Form.Control size="sm" type="text" placeholder="First Name" className="mb-2 py-1" name="first_name" value={userData.first_name} onChange={handleChange} />
                          <Form.Control size="sm" type="text" placeholder="Last Name" className="mb-2 py-1" name="last_name" value={userData.last_name} onChange={handleChange} />
                          <Form.Select size="sm" aria-label="Default select example" className="mb-2 py-1" name="gender" value={userData.gender} onChange={handleChange}>
                            <option selected value="His">His</option>
                            <option value="Her" >Her</option>
                            <option value="Them">Them</option>
                          </Form.Select>
                          <Form.Select size="sm" aria-label="Default select example" className="mb-2 py-1" name="language" value={userData.language} onChange={handleChange}>
                            <option disabled>Select Language</option>
                            {languages.map((lang, index) => (
                              <option key={index} value={lang.name}>{lang.name}</option>
                            ))}

                          </Form.Select>


                        </Form.Group>

                        <Button onClick={() => setValue2('2')} variant="primary" size="sm" type="button">
                          Save and Next
                        </Button>
                      </Form>

                    </TabPanel>
                    <TabPanel value="2" sx={{px: 0, pt: 4, pb: 2 }} style={{ textAlign: "center" }} >
                      <Form.Control  className='py-1 ' as="textarea" rows={11} style={{ marginBottom: 10 }} name="description" value={userData.description} onChange={handleChange} />
                      <Button onClick={() => setValue2('3')} variant="primary" size="sm" type="button">
                        Save and Next
                      </Button>
                    </TabPanel>
                    <TabPanel value="3" sx={{ px: 0, pt: 4, pb: 2 }} style={{ textAlign: "center" }}>
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            size="sm"
                            type="email"
                            readOnly
                            placeholder="example@domain.com"
                            className="mb-2 py-1"
                            name="email"
                            value={userData.email}
                            onChange={handleChange}
                          />
                          <Form.Select
                            size="sm"
                            aria-label="Default select example"
                            className="mb-2 py-1"
                            name="country_id"
                            value={userData.country_id}
                            onChange={handleChange}
                          >
                            {countries.map(c => (
                              <option key={c.id} value={c.id}>{c.name}</option>
                            ))}
                          </Form.Select>

                          <Form.Select
                            size="sm"
                            aria-label="Default select example"
                            className="mb-2 py-1"
                            name="city_id"
                            value={userData.city_id}
                            onChange={handleChange}
                          >
                            <option>Select City</option>
                            {cities.map(city => (
                              <option key={city.id} value={city.id}>{city.name}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        <Button
                          onClick={() => setValue2('4')}
                          variant="primary"
                          size="sm"
                          type="button"
                        >
                          Save and Next
                        </Button>
                      </Form>
                    </TabPanel>
                    <TabPanel value="4" sx={{ px: 0, pt: 4, pb: 2 }} style={{ textAlign: "center" }}>
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <InputGroup size="sm" className='mb-2'>
                            <InputGroup.Text > <FacebookIcon /> </InputGroup.Text>
                            <Form.Control type="text" placeholder="facebook.com/xxxxxxx" className="" name="facebook" value={userData.facebook} onChange={handleChange} />
                          </InputGroup>
                          <InputGroup size="sm" className='mb-2'>
                            <InputGroup.Text > <LinkedInIcon /> </InputGroup.Text>
                            <Form.Control type="text" placeholder="linkedin.com/xxxxxxx" className="" name="linkedin" value={userData.linkedin} onChange={handleChange} />
                          </InputGroup>
                          <InputGroup size="sm" className='mb-2'>
                            <InputGroup.Text>     <Tooltip arrow title="Twitter">
                              <TwitterIcon /></Tooltip> </InputGroup.Text>
                            <Form.Control tooltip='twiiter' type="text" placeholder="twitter.com/xxxxxxx" className="" name="twitter" value={userData.twitter} onChange={handleChange} />
                          </InputGroup>
                        </Form.Group>

                        <Button onClick={() => setValue2('5')} variant="primary" size="sm" type="button">
                          Save and Next
                        </Button>
                      </Form>
                    </TabPanel>
                    <TabPanel value="5" sx={{ px: 0, pt: 4, pb: 2 }}>
                      <Form className='text-center'>
                        <Form.Group className="mb-3 text-start" controlId="formBasicEmail">

                          <Form.Control size="sm" type="text" placeholder="Organization" className="mb-2 py-1" name="organization" value={userData.organization} onChange={handleChange} />
                          <Form.Control size="sm" type="text" placeholder="Designation" className="mb-2 py-1" name="designation" value={userData.designation} onChange={handleChange} />
                          <div style={{width: '278px'}}>
                          <Select
  size="sm"
  isMulti
  autoSize={true}
  className="basic-multi-select mb-2"
  placeholder="Ecosystem"
  classNamePrefix="select"
  options={ECOSYSTEM_OPTIONS}
  name="ecosystem[]"
  value={selectedOptions} // Ensure selectedOptions state is bound here
  onChange={handleChangeMultiple}
  menuPlacement="auto"
  menuPosition="absolute"
/>


</div>

                          

                          <Form.Select size="sm" id="area-of-interest" aria-label="Default select example" className="mb-2 py-1" name="intrests_area" value={userData.intrests_area} onChange={handleChange}>
                            <option selected="">Area of Interest...</option>
                            {areaofinterest.map((area, index) => (
                              <option key={index} value={area}>{area}</option>
                            ))}
                          </Form.Select>
                          <Form.Select size="sm" aria-label="Default select example" className="mb-2 py-1" id="describes" name="describe_id" value={userData.describe_id} onChange={handleChange}>

                            <option selected="" value="">Industries &amp; Sectors...</option>

                            {user_describe.map(c => (
                              <option key={c.id} value={c.id}>{c.name}</option>
                            ))}
                          </Form.Select>

                          


                        </Form.Group>
                        <Button variant="primary" size="sm" onClick={handleSubmit}>
                          Save
                        </Button>
                      </Form>
                    </TabPanel>
                  </TabContext>
                </Box>
              </TabPanel>
              <TabPanel value="2" className='w-100 pt-0 pb-2 px-2'>
                <Accordion defaultActiveKey="0" className='w-100'>
                  {memberships.map((mem, index) => (
                    <Accordion.Item eventKey={mem.id} key={mem.id}>
                      <Accordion.Header>{mem.pavilion_name}</Accordion.Header>
                      <Accordion.Body className="p-1 h6">
                        <ListGroup className="overflow-hidden">
                          <ListGroup.Item className="d-flex justify-content-between py-1 px-2">
                            <Typography variant="medium" className="fw-bold">Membership</Typography>
                            <Typography variant="medium">{mem.membership_name}</Typography>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between py-1 px-2">
                            <Typography variant="medium" className="fw-bold">Purchase Date</Typography>
                            <Typography variant="medium">{formatDate(mem.purchased_at)}</Typography>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between py-1 px-2">
                            <Typography variant="medium" className="fw-bold">Expiry Date</Typography>
                            <Typography variant="medium">{formatDate(mem.expiration_at)}</Typography>
                          </ListGroup.Item>
                        </ListGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
				{membershipsCount===0&&('You are currently not a member of any Pavilion')
                  }
                </Accordion>
              </TabPanel>
              <TabPanel value="3" className='w-100 pt-0 pb-2 px-2'>
                <Accordion defaultActiveKey="0" className='w-100 px-0 mx-0'>
                  {roles.map((role, index) => (
                    <Accordion.Item eventKey={role.role_id}>
                      <Accordion.Header >{role.pavilion_name}</Accordion.Header>
                      <Accordion.Body className="p-2 h6">
                        <ListGroup className="overflow-hidden" >
                          <ListGroup.Item className='d-flex justify-content-between border-0 py-1 px-2'>
                            <Typography variant="medium" className='fw-bold'>Role</Typography>
                            <Typography variant="medium" >{role.role_name}</Typography>
                          </ListGroup.Item>
                        </ListGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
				  {roleCount===0&&('you are not allocated an authoritative or admin role on any Pavilion')
                  }
                </Accordion>
              </TabPanel>
            </Box>
          </TabContext>
        </div>
      </ListGroup.Item>
    </ListGroup>
  )
}

export default PRFLUpdateProfile
