import React, { useState, useEffect, useRef } from 'react'
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DropdownButton from 'react-bootstrap/DropdownButton';
import PreLoader from '../../../PreLoader';
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import StarIcon from '@mui/icons-material/Star'
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PVLNEventDetails from './PVLNEventDetails';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Box from "@mui/material/Box";
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
// import Upload from "../../../Cropper/Upload";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Dropdown } from 'react-bootstrap';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// import ToggleButton from 'react-bootstrap/ToggleButton';
// import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Collapse from 'react-bootstrap/Collapse';
import { ECOSYSTEM_OPTIONS } from '../../../PavilionConstants';
import Select from 'react-select';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Popup from "../../../Cropper/Popup";
import { styled } from "@mui/material/styles";
import eventBanner from '../../../../assets/img/default-event-banner.jpg';
import http, { baseUrl } from '../../../../http';
import CRUDDropdown from '../../../CRUDDropdown';
import Image from 'react-bootstrap/Image';

import { CKEditor, ImageInsert } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CropperPopup from "../../../Cropper/Popup";
import pubThumbnail from '../../../../assets/img/default-pubn-thumbnail.jpg';

import { PLSIZE, formatDate } from '../../../PavilionConstants';
import Swal from 'sweetalert2'
import Spinner from 'react-bootstrap/Spinner';
import OpenIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/VisibilityOff';

const CropperInput = styled("input")({
  display: "none"
});

// import PieChart from "../../../Charts/PieChart"

function PVLNEvents(props, { handleClick }) {
  const theme = localStorage.getItem("data-bs-theme");

  const [progress, setProgress] = useState(false);
  const [permissions, setPermissions] = useState({});
  const scrollContainerRef = useRef(null);

  const [pageTitle, setPageTitle] = useState('Events');

  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE} />);
  const token = localStorage.getItem('token');
  const [editorData, setEditorData] = useState('');
  const [imageFile, setFile] = useState(localStorage.getItem('bucketName') + '/images/noimage.webp');
  const [imagePress, setImagePress] = useState(eventBanner);
  const [imagePress2, setImagePress2] = useState('');

  const [events, setEvents] = useState([]);
  const [eventCategories, setEventCategories] = useState([]);
  const [image2] = useState(eventBanner);
  const [eventData, setEventsData] = useState(false);
  const onChange = (cropper: CropperRef) => {
    console.log(cropper.getCoordinates(), cropper.getCanvas());
  };
  const [eventToEdit, setEventToEdit] = useState(null);


  // let timeStamp = Date.parse("14 Oct 2022");
  //alert(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timeStamp));
  const [showEvents, setShowEvents] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [intesetedmsg, setIntesetedmsg] = useState('interested');
  const [unintesetedmsg, setUnintesetedmsg] = useState('uninterested');
  const [intesetedmsgcolor, setIntesetedmsgcolor] = useState('primary');
  const [unintesetedmsgcolor, setUnintesetedmsgcolor] = useState('secondary');

  const [show, setShow] = useState(false);
  const handleParentClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setEventsData(false);
  }
  const [dsType, setDSType] = useState(false);
  const handleDSTypeClick = (e) => {
    if (e.target.value === "0") {
      setDSType(true)
    } else {
      setDSType(false)
    }
  }
  const [state, setState] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState(eventBanner);

  const [btnText, setBtnText] = useState(["Current", "Know More"])
  const [rowIndex, setRowIndex] = useState();
  const handleEventClick = (idx) => {
    //  if (idx === 0) {
    setShowEvents(1)
    setRowIndex(idx)
    //  }
  }

  const [isFree, setIsFree] = useState(false);
  const [isGuests, setIsGuests] = useState(false)

  const [etype, setEType] = useState(null);
  const handleEventTypeClick = (e) => {
    setEType(e.target.value)
  }
  const handleImageError = (e) => {
    e.target.src = localStorage.getItem('bucketName') + '/images/noimage.webp';
  };
  //input file dialogue
  const onFileChange = (e) => {
    e.preventDefault();
    const dataValue = e.currentTarget.getAttribute("data-value");
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();
    reader.onload = () => {
      // getUploadedFile(reader.result);
      if (dataValue === "EVENTCROP") {
        setImagePress2(reader.result);
        setImagePress(reader.result);
        setFormData({ ...formData, image: files[0] });
        setOpen(true);
      }
    };


    //setFormData({ ...formData, image: e.target.files[0]});

    reader.readAsDataURL(files[0]);
  };




  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData({ ...formData, description: data });
  };
  //input file dialog
  // const [show, setShow] = useState(false);
  function handleDropdown(event) {
    event.stopPropagation();
    // console.log('handleChildClick');
  }

  function handleDelete(id) {
    // console.log('handle Delete');
    Swal.fire({
      title: "Are you sure to delete this event?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        http().delete(`/events/${id}`)
          .then(response => {
            Swal.fire({
              title: "Deleted!",
              text: "Event has been deleted.",
              icon: "success"
            });
            // Fetch the updated list of events after deletion
            fetchEvents();
          })
          .catch(error => {
            Swal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error"
            });
          });
      }
    });
  }


  // Function to fetch events from an API
  const fetchEvents = async () => {
    try {
      const response = await http().get(`/events/${props.idx}?page=${page}`);
      setPermissions(response.data.data.permission);
      if (response.status === 200 && response.data?.data?.events?.data?.length > 0) {
        setItems(response.data.data.events.data);
        setTotalPages(response.data.data.events.last_page);
      } else {
        setLoadMsg('No Record Found.');
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoadMsg('Error fetching events');
    }

  };

  useEffect(() => {
    fetchEvents();
  }, []);



  useEffect(() => {
    http().get('/eventcategories/' + props.idx).then((res) => {
      setEventCategories(res.data.data);
    });
  }, []);


  const [formData, setFormData] = useState({
    name: '',
    pavilion_id: props.idx,
    timezone: '',
    category_id: '',
    description: '',
    location: '',
    organizer: '',
    capacity: '',
    frequency: '',
    zoom_link: '',
    start_date: '',
    end_date: '',
    guest_or_all: '',
    invite_all_members: '',
    event_type: '',
    event_meeting_type: '',
    meeting_status: '',
    online_price: '',
    offline_price: '',
    status: '',
    meeting_type: '',
    image: '',

  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log(formData);
      setProgress(true);
      const response = await http().post('/events', formData);
      if (response.data) {
        Swal.fire({
          title: "Your Event created successfully",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: '#e30613'
        });
        setProgress(false);
        setShow(false);
        fetchEvents();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    try {
      // console.log(formData);
      setProgress(true);
      const response = await http().post('/eventupdate', formData);
      if (response.data) {
        Swal.fire({
          title: "Your Event updated successfully",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: '#e30613'
        });
        setShow(false);
        setProgress(false);
        fetchEvents();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (eventToEdit) {
      setFormData({
        ...formData,
        ...eventToEdit,
      });
    }
  }, [eventToEdit]);
  // Event Edit Work
  function handleEdit(id) {
    setShow(true);
    // Geting Data for edit event
    http().get(`/events/${id}/edit`).then((response) => {
      setEventsData(true);
      // console.log(response.data.data.eventRecord);
      setEventToEdit(response.data.data.eventRecord); // Set the event data to the state
      setImagePress(localStorage.getItem('bucketName') + '/images/events/'+response.data.data.eventRecord.image);
    });
  }

  // End Event Edit work

  useEffect(() => {
    setFormData({ ...formData, image: imagePress });
  }, [imagePress]);

  //Infinite Scroll
  //`${baseUrl}/events/${props.idx}?page=${page}`, {  
  const fetchData = async (page) => {
    // console.log('pageno: ', page);
    // console.log('hasmore: ', hasMore);
    // console.log('total pages fetchData: ', totalPages)

    if (page <= totalPages) {
      http().get(`/events/${props.idx}?page=${page}`).then((response) => {
        setPage(response.data.data.events.current_page);
        setTotalPages(response.data.data.events.last_page);
        setItems([...items, ...response.data.data.events.data]);
        setHasMore(true);
        setLoadMsg(<PreLoader size={PLSIZE} />)
        setPermissions(response.data.data.permission);
      });
    } else {
      setHasMore(false)
      setLoadMsg('No More Records...')
    }

  };
  const [filterData, setFilterData] = useState({
    when: '',
    where: '',
    format: '',
    eventType: '',
    searchEvents: '',
  });

  const handleFilterChange = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };


  const [isInterested, setIsInterested] = useState('primary');
  const handleInterestedClick = (id,interestedmsgdata) => {
if(interestedmsgdata=='interested'){
  if(intesetedmsgcolor=='primary'){
    setIntesetedmsgcolor('secondary')
  }else{
    setIntesetedmsgcolor('primary')
  }
}else{
  if(unintesetedmsgcolor=='primary'){
    setUnintesetedmsgcolor('secondary')
  }else{
    setUnintesetedmsgcolor('primary')
  }
}

    /*var button = document.getElementById('event_'+id);
    if(button.style.textDecoration==='none'){
       button.style.textDecoration='line-through'
       button.style.backgroundColor='#fff'
       button.style.color='#e30613'
       button.style.border='1px solid #e30613'
    }else{
      button.style.textDecoration='none'
      button.style.backgroundColor='#e30613'
      button.style.color='#fff'
      button.style.border='1px solid #e30613'
    }*/
	http().post('/webeventinterest/'+id, {
      event: "interested",
    }).then((response) => {
	Swal.fire({
          title: response.data.data.event,
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: '#e30613'
        });
    }).catch((error) => {
      console.error('Error:', error);
      Swal.fire({
        title: 'An error occurred. Please try again.',
        icon: 'error',
      });
    });
	
  }
  const handleFilterSubmit = async (e) => {
    e.preventDefault();

    http().post('/searchevents', {
      event_pavilion_id: props.idx,
      event_search_type: filterData.when,
      event_location: filterData.where,
      text_search_events: filterData.searchEvents,
      event_category_id: filterData.eventType,
    }).then((response) => {
      // alert(response.ok===true)


      setItems([...items, ...response.data.data.events.data]);
      console.log('search results: ', response.data); // Handle the response data
      setShowEvents(0)
      setPageTitle('Search Results')

    }).catch((error) => {
      console.error('Error:', error);
      Swal.fire({
        title: 'An error occurred. Please try again.',
        icon: 'error',
      });
    });

  };

  return (
    <>
      <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
        <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
          <div>
            <IconButton className='p-1 m-1 btn btn-outline-mode' onClick={showEvents > 0 ? (() => setShowEvents(0)) : (event => props.handleClick('PVLN-MENU', props.idx))}><ArrowBackIcon /></IconButton>
            <Typography variant="small">
              {showEvents === 0 && pageTitle}
              {showEvents === 1 && "Events Details"}
              {showEvents === 2 && "Search Events"}
            </Typography>
          </div>
          <IconButton className='p-1 m-1 btn btn-outline-mode' onClick={event => props.handleClick('PVLN-HOME', props.idx)}><Home /></IconButton>
          <div>
            {permissions['create-events'] && (<IconButton className='p-1 m-1 btn btn-outline-mode' onClick={handleShow}><PostAddIcon /></IconButton>)}
            {permissions['search-events'] && (<IconButton className='p-1 m-1 btn btn-outline-mode' onClick={() => setShowEvents(2)}><FilterAltIcon /></IconButton>)}
          </div>
        </ListGroup.Item>
        {showEvents === 0 && (
          <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
            <div className="container slim-scrollbar mh-70  py-0" style={{ overflow: "auto" }} ref={scrollContainerRef}>
              <InfiniteScroll
                pageStart={1}
                hasMore={hasMore}
                loadMore={fetchData}
                getScrollParent={() => scrollContainerRef.current}
                useWindow={false}
                initialLoad={false}
              >
                <Row xs={1} md={1} className="g-0 " >
                  {items.map((event, index) => (<>
                    <Col key={index} className="py-2">
                      <Card key={index} className="mb-0 shadow-sm bg-light" style={{ cursor: "pointer" }} >
                        <Card.Img style={{ pointerEvents: 'auto' }} variant="top" src={`${localStorage.getItem('bucketName')}/images/events/${event.image}`} onClick={() => handleEventClick(event.id)} />
                        <Card.ImgOverlay style={{ pointerEvents: 'none' }}>
                          <h4><span className="badge bg-primary">{formatDate(event.start_date)}</span></h4>
                        </Card.ImgOverlay>
                        <Card.Body className="p-2 d-flex flex-column align-items-start justify-content-between " style={{ minHeight: "75px" }}>
                          <Card.Title onClick={() => handleEventClick(event.id)} className="pt-0 my-0 d-flex align-items-center justify-content-between w-100" style={{ lineHeight: "0.7" }}>
                            <Typography key={index} variant="large" className="d-flex align-items-center text-dark small" >
                              <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName') + '/' + event.user_image} name={event.username} size='50' />
                              <div className="ms-2"><strong>{event.name}</strong> <br></br>
                                <small className="text-muted">Category: {event.category_name}</small>
                              </div>
                            </Typography>
                            {(permissions['edit-events'] || permissions['delete-events']) && (
                              <CRUDDropdown
                                title={<MoreVertIcon fontSize="small" />}
                                handleDropdown={handleDropdown}
                                editTitle={permissions['edit-events'] ? "Edit Event" : null}
                                editClick={permissions['edit-events'] ? () => handleEdit(event.id) : null}
                                deleteTitle={permissions['delete-events'] ? "Delete Event" : null}
                                deleteClick={permissions['delete-events'] ? () => handleDelete(event.id) : null}
                              />
                            )}
                          </Card.Title>
                          <Card.Text className='d-flex align-items-center my-2 text-dark'>
                            <LocationOnOutlinedIcon /> {event.location}
                          </Card.Text>
                          {/* <Button variant="primary" size="sm">{btnText[idx]}</Button>  */}
                        </Card.Body>
                        <Card.Footer className="mx-0 px-2 py-1 d-flex justify-content-between align-items-center border-top-0">
                          <small>You and {event.total_members -1 } more going</small>
                          {/*<Button key={index} id={'event_'+ event.id} style={{textDecoration: 'line-through',pointerEvents:'auto'}} onClick={()=>handleInterestedClick(event.id)} variant='outline-primary' size="sm" className="px-1 py-0">*/}
						  {/*<Button key={index} id={'event_'+ event.id} style={{textDecoration: 'line-through',pointerEvents:'auto'}} onClick={()=>handleInterestedClick(event.id)} variant='outline-primary' size="sm" className="px-1 py-0">*/}
                          
                          {event.interested?
                          (<Button value={index} key={index} style={{pointerEvents:'auto'}} onClick={(e)=>handleInterestedClick(event.id,intesetedmsg)} variant={intesetedmsgcolor} size="sm"  className="px-1 py-0"><StarIcon className="mb-1" sx={{ width: '15px', height: '15px' }} />Interested</Button>):
                          (<Button value={index} key={index} style={{pointerEvents:'auto'}} onClick={(e)=>handleInterestedClick(event.id,unintesetedmsg)} variant={unintesetedmsgcolor} size="sm" className="px-1 py-0"><StarIcon className="mb-1" sx={{ width: '15px', height: '15px' }} />Interested</Button>)                         
                          }
                         
                         
                        </Card.Footer>
                      </Card>
                    </Col>
                  </>))}
                </Row>
              </InfiniteScroll>
              <div className="loader my-2 text-center" key={0} >
                {loadMsg}
              </div>
            </div>
          </ListGroup.Item>
        )}
        {showEvents === 1 && (<PVLNEventDetails pIndex={props.idx} rowIndex={rowIndex} handleClick={handleClick} />)}
        {showEvents === 2 && (
          <ListGroup.Item className="mx-0 px-0 d-flex align-items-start justify-content-center h-100 border-0 bg-light bg-opacity-75">
            <Container className='text-center'>
              <Form className="mx-0 px-0" onSubmit={handleFilterSubmit}>
                <Form.Select size="sm" id="when" name="when" className="my-1 py-1" value={filterData.when} onChange={handleFilterChange}>
                  <option value="" selected disabled>When</option>
                  <option value="previous">Previous</option>
                  <option value="week">This Week</option>
                  <option value="month">This Month</option>
                  <option value="upcoming">Upcoming</option>
                </Form.Select>
                <Form.Select name="where" size="sm" className="my-1 py-1" value={filterData.where} onChange={handleFilterChange}>
                  <option value="" selected disabled>Where</option>
                  <option value="0">Onsite</option>
                  <option value="1">Online</option>
                  <option value="2">Both</option>
                </Form.Select>
                <Form.Select name="format" size="sm" className="my-1 py-1" value={filterData.format} onChange={handleFilterChange}>
                  <option value="" selected disabled>Format</option>
                  <option value="0">Meeting</option>
                  <option value="1">Webinar</option>
                </Form.Select>
                <Form.Select name="eventType" size="sm" className="my-1 py-1" value={filterData.eventType} onChange={handleFilterChange}>
                  <option value="" selected disabled>Event Type</option>
                  {eventCategories.map((cat) => (
                    <option key={cat.id} value={cat.id}>{cat.name}</option>
                  ))}
                  <option value="">View All</option>
                </Form.Select>
                <Form.Control size="sm" name="searchEvents" type="text" placeholder="Search Events" className="my-1 py-1" value={filterData.searchEvents} onChange={handleFilterChange} />
                <Button size="sm" type="submit">Search</Button>
              </Form>
            </Container>
          </ListGroup.Item>
        )}

      </ListGroup>
      <Modal show={show} size="lg" onHide={handleParentClose} data-bs-theme={theme} centered>
        <Modal.Header closeButton className="py-1">
          {/* <Modal.Title className="lead" >Create Event</Modal.Title> */}
          <Modal.Title className="lead">{eventData ? 'Edit Event' : 'Create Event'}</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          {eventToEdit ? (
            <Row>
              <Col md={4} className="d-flex flex-column justify-content-center">
                <div>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                    <Box my={2} >
                      <label htmlFor="fileBanner1">
                        <CropperInput accept="image/*" data-value="EVENTCROP" id="fileBanner1" type="file" onChange={onFileChange} />
                        <Image style={{ borderRadius: "10px", cursor: "pointer", width: "100%", height: "100%" }} src={imagePress} />
                      </label>
                    </Box>
                    <CropperPopup
                      open={open} image={imagePress2} handleClose={handleClose}
                      getCroppedFile={(image) => {
                        setImagePress(image)
                        handleClose();
                      }}
                      aspectRatio={16 / 10}
                    />
                  </Box>
                </div>
              </Col>
              <Col md={8}>
                <Row>
                  <Form.Group as={Col} md={6} className='floating-labe'>
                    <Form.Select required size="sm" id="category" className="my-1  py-1" name="category_id" value={formData.category_id} onChange={handleChange}>
                      <option value="" selected disabled>Event Category</option>
                      {eventCategories.map((cat, index) => (
                        <option value={cat.id}>{cat.name}</option>
                      ))}
                    </Form.Select>

                  </Form.Group>
                  <ToggleButtonGroup as={Col} md={6} type="radio" className="d-flex align-items-center" name="options" defaultValue={0}>
                    <ToggleButton onChange={handleEventTypeClick} id="tbg-onsite" variant="outline-secondary" size="sm" value={0}>
                      On Site
                    </ToggleButton>
                    <ToggleButton onChange={handleEventTypeClick} id="tbg-online" variant="outline-secondary" size="sm" value={1}>
                      Online
                    </ToggleButton>
                    <ToggleButton onChange={handleEventTypeClick} id="tbg-both" variant="outline-secondary" size="sm" value={2}>
                      Both
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Row>
                <Form.Control type="text" size="sm" name="event-name" placeholder="Event Name" className="my-2  py-1" name="name" value={formData.name} onChange={handleChange} />

                {(etype === "0" || etype === "2") && (<Form.Control type="text" size="sm" name="location" placeholder="Location" className="my-2  py-1" name="location" value={formData.location} onChange={handleChange} />)}
                {(etype === "1" || etype === "2") && (<Form.Control type="text" size="sm" name="regnLink" placeholder="Registration Link" className="my-2  py-1" name="zoom_link" value={formData.zoom_link} onChange={handleChange} />)}
                <Form.Group className="d-flex">
                  <Form.Select required size="sm" id="frequency" name="frequency" className="my-1 me-2  py-1" value={formData.frequency} onChange={handleChange}>
                    <option value="" selected disabled>Frequency</option>
                    <option value="Once">Occurs Once</option>
                    <option value="Every Week">Every Week</option>
                    <option value="Every Month">Every Month</option>
                  </Form.Select>
                  <Form.Select required size="sm" id="timezone" name="timezone" className="my-1  py-1" value={formData.timezone} onChange={handleChange}>
                    <option value="0" selected disabled>Time Zone</option>
                    <option value="Europe/London">(GMT+1:00) London</option>
                    <option value="Asia/Riyadh">(GMT+3:00) Riyadh</option>
                    <option value="Asia/Dubai">(GMT+4:00) Dubai</option>
                    <option value="Asia/Calcutta">(GMT+5:30) India</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className='d-flex' >
                  <Form.Control onFocus={(e) => { e.target.type = 'date' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="start_date" placeholder="Start Date" className="my-1 me-2 w-50 py-1"   value={formData.start_date ? new Date(formData.start_date).toLocaleDateString('en-CA') : ''}  onChange={handleChange} />
                  <Form.Control onFocus={(e) => { e.target.type = 'time' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="start_time" placeholder="Start TIme" className="my-1 w-50  py-1" value={formData.start_date ? formData.start_date.split(' ')[1].substring(0, 5) : ''} onChange={handleChange} />
                </Form.Group>
                <Form.Group className='d-flex' >
                  <Form.Control onFocus={(e) => { e.target.type = 'date' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="end_date" placeholder="End Date" className="my-1 me-2 w-50 py-1"   value={formData.end_date ? new Date(formData.end_date).toLocaleDateString('en-CA') : ''} onChange={handleChange} />
                  <Form.Control onFocus={(e) => { e.target.type = 'time' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="end_time" placeholder="End TIme" className="my-1 w-50  py-1" value={formData.end_date ? formData.end_date.split(' ')[1].substring(0, 5) : ''} onChange={handleChange} />
                </Form.Group>
                <Form.Control type="text" size="sm" name="organizer" placeholder="Organizer" className="my-2  py-1" value={formData.organizer} onChange={handleChange} />
                <Form.Group className='pt-1'>
                  {/* <Form.Label>Purpose Description</Form.Label> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data="<p>Description</p>"
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Event Description', editor);
                    }}
                    data={formData.description}
                    onChange={handleEditorChange}

                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                    config={{
                      placeholder: 'Description',
                    }}
                  />

                </Form.Group>
                {/* <Form.Control type="text" size="sm" name="organiser" placeholder="Organiser" className="my-2  py-1" /> */}


                <Row>
                  <ToggleButtonGroup as={Col} md={6} type="radio" className="d-flex align-items-center" defaultValue={1} name="meeting_status">
                    Meeting Status &nbsp;
                    <ToggleButton onChange={handleChange} onChange={() => setIsFree(false)} id="tbg-free" variant="outline-secondary" size="sm" value={1}>
                      Free       </ToggleButton>
                    <ToggleButton onChange={handleChange}
                      aria-controls="meetingPrice"
                      aria-expanded={isFree} id="tbg-paid" onChange={() => setIsFree(true)} variant="outline-secondary" size="sm" value={2}>
                      Paid
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup as={Col} md={6} type="radio" className="d-flex align-items-center mb-2" name="event_type" defaultValue={1}>
                    Event Type &nbsp;  <ToggleButton id="tbg-open" variant="outline-secondary" size="sm" value={1} onChange={handleChange}>
                      Open       </ToggleButton>
                    <ToggleButton id="tbg-close" variant="outline-secondary" size="sm" value={2} onChange={handleChange}>
                      Close
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Row>
                <Collapse in={isFree}>
                  <Form.Control type="text" id="meetingPrice" size="sm" name="online_price" placeholder="$ Online price for this meeting" className="my-2  py-1" value={formData.online_price} onChange={handleChange} />
                </Collapse>
                <Form.Control type="Number" size="sm" name="capacity" placeholder="Capacity" onkeypress="return /[0-9]/i.test(event.key)" className="my-2  py-1" value={formData.capacity} onChange={handleChange} />

                <ToggleButtonGroup type="radio" className="d-flex align-items-center" name="guest_or_all" defaultValue={1}>
                  Invite &nbsp;<ToggleButton onChange={() => setIsGuests(false)} id="tbg-inviteall" variant="outline-secondary" size="sm" value={1}>
                    Invite all members of this Pavilion</ToggleButton>
                  {/* <ToggleButton onChange={() => setIsGuests(true)} id="tbg-guests" variant="outline-secondary" size="sm" value={2}>
                    Guests List
                  </ToggleButton> */}
                </ToggleButtonGroup>
                {/* <Collapse in={isGuests}>
                  <Select size="sm" isMulti autosize={true} className="basic-multi-select mt-2 w-100" placeholder="Ecosystem" classNamePrefix="select" options={ECOSYSTEM_OPTIONS} />
                </Collapse> */}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={4} className="d-flex flex-column justify-content-center">
                <div>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                    <Box my={2} >
                      <label htmlFor="fileBanner1">
                        <CropperInput accept="image/*" data-value="EVENTCROP" id="fileBanner1" type="file" onChange={onFileChange} />
                        <Image style={{ borderRadius: "10px", cursor: "pointer", width: "100%", height: "100%" }} src={imagePress} />
                      </label>
                    </Box>
                    <CropperPopup
                      open={open} image={imagePress2} handleClose={handleClose}
                      getCroppedFile={(image) => {
                        setImagePress(image)
                        handleClose();
                      }}
                      aspectRatio={16 / 10}
                    />
                  </Box>
                </div>
              </Col>
              <Col md={8}>
                <Row>
                  <Form.Group as={Col} md={6} className='floating-labe'>
                    <Form.Select required size="sm" id="category" className="my-1  py-1" name="category_id" value={formData.category_id} onChange={handleChange}>
                      <option value="" selected disabled>Event Category</option>
                      {eventCategories.map((cat, index) => (
                        <option value={cat.id}>{cat.name}</option>
                      ))}
                    </Form.Select>

                  </Form.Group>
                  <ToggleButtonGroup as={Col} md={6} type="radio" className="d-flex align-items-center" name="options" defaultValue={0}>
                    <ToggleButton onChange={handleEventTypeClick} id="tbg-onsite" variant="outline-secondary" size="sm" value={0}>
                      On Site
                    </ToggleButton>
                    <ToggleButton onChange={handleEventTypeClick} id="tbg-online" variant="outline-secondary" size="sm" value={1}>
                      Online
                    </ToggleButton>
                    <ToggleButton onChange={handleEventTypeClick} id="tbg-both" variant="outline-secondary" size="sm" value={2}>
                      Both
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Row>
                <Form.Control type="text" size="sm" name="event-name" placeholder="Event Name" className="my-2  py-1" name="name" value={formData.name} onChange={handleChange} />

                {(etype === "0" || etype === "2") && (<Form.Control type="text" size="sm" name="location" placeholder="Location" className="my-2  py-1" name="location" value={formData.location} onChange={handleChange} />)}
                {(etype === "1" || etype === "2") && (<Form.Control type="text" size="sm" name="regnLink" placeholder="Registration Link" className="my-2  py-1" name="zoom_link" value={formData.zoom_link} onChange={handleChange} />)}
                <Form.Group className="d-flex">
                  <Form.Select required size="sm" id="frequency" name="frequency" className="my-1 me-2  py-1" value={formData.frequency} onChange={handleChange}>
                    <option value="" selected disabled>Frequency</option>
                    <option value="Once">Occurs Once</option>
                    <option value="Every Week">Every Week</option>
                    <option value="Every Month">Every Month</option>
                  </Form.Select>
                  <Form.Select required size="sm" id="timezone" name="timezone" className="my-1  py-1" value={formData.timezone} onChange={handleChange}>
                    <option value="0" selected disabled>Time Zone</option>
                    <option value="Europe/London">(GMT+1:00) London</option>
                    <option value="Asia/Riyadh">(GMT+3:00) Riyadh</option>
                    <option value="Asia/Dubai">(GMT+4:00) Dubai</option>
                    <option value="Asia/Calcutta">(GMT+5:30) India</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className='d-flex' >
                  <Form.Control onFocus={(e) => { e.target.type = 'date' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="start_date" placeholder="Start Date" className="my-1 me-2 w-50 py-1" value={formData.start_date} onChange={handleChange} />
                  <Form.Control onFocus={(e) => { e.target.type = 'time' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="start_time" placeholder="Start TIme" className="my-1 w-50  py-1" value={formData.start_time} onChange={handleChange} />
                </Form.Group>
                <Form.Group className='d-flex' >
                  <Form.Control onFocus={(e) => { e.target.type = 'date' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="end_date" placeholder="End Date" className="my-1 me-2 w-50 py-1" value={formData.end_date} onChange={handleChange} />
                  <Form.Control onFocus={(e) => { e.target.type = 'time' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="end_time" placeholder="End TIme" className="my-1 w-50  py-1" value={formData.end_time} onChange={handleChange} />
                </Form.Group>
                <Form.Control type="text" size="sm" name="organizer" placeholder="Organizer" className="my-2  py-1" value={formData.organizer} onChange={handleChange} />
                <Form.Group className='pt-1'>
                  {/* <Form.Label>Purpose Description</Form.Label> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data="<p>Description</p>"
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Event Description', editor);
                    }}
                    data={editorData}
                    onChange={handleEditorChange}

                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                    config={{
                      placeholder: 'Description',
                    }}
                  />

                </Form.Group>
                {/* <Form.Control type="text" size="sm" name="organiser" placeholder="Organiser" className="my-2  py-1" /> */}


                <Row>
                  <ToggleButtonGroup as={Col} md={6} type="radio" className="d-flex align-items-center" defaultValue={1} name="meeting_status">
                    Meeting Status &nbsp;
                    <ToggleButton onChange={handleChange} onChange={() => setIsFree(false)} id="tbg-free" variant="outline-secondary" size="sm" value={1}>
                      Free       </ToggleButton>
                    <ToggleButton onChange={handleChange}
                      aria-controls="meetingPrice"
                      aria-expanded={isFree} id="tbg-paid" onChange={() => setIsFree(true)} variant="outline-secondary" size="sm" value={2}>
                      Paid
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup as={Col} md={6} type="radio" className="d-flex align-items-center mb-2" name="event_type" defaultValue={1}>
                    Event Type &nbsp;  <ToggleButton id="tbg-open" variant="outline-secondary" size="sm" value={1} onChange={handleChange}>
                      Open       </ToggleButton>
                    <ToggleButton id="tbg-close" variant="outline-secondary" size="sm" value={2} onChange={handleChange}>
                      Close
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Row>
                <Collapse in={isFree}>
                  <Form.Control type="text" id="meetingPrice" size="sm" name="online_price" placeholder="$ Online price for this meeting" className="my-2  py-1" value={formData.online_price} onChange={handleChange} />
                </Collapse>
                <Form.Control type="Number" size="sm" name="capacity" placeholder="Capacity" onkeypress="return /[0-9]/i.test(event.key)" className="my-2  py-1" value={formData.capacity} onChange={handleChange} />

                <ToggleButtonGroup type="radio" className="d-flex align-items-center" name="guest_or_all" defaultValue={1}>
                  Invite &nbsp;<ToggleButton onChange={() => setIsGuests(false)} id="tbg-inviteall" variant="outline-secondary" size="sm" value={1}>
                    Invite all members of this Pavilion</ToggleButton>
                  {/* <ToggleButton onChange={() => setIsGuests(true)} id="tbg-guests" variant="outline-secondary" size="sm" value={2}>
                    Guests List
                  </ToggleButton> */}
                </ToggleButtonGroup>
                {/* <Collapse in={isGuests}>
                  <Select size="sm" isMulti autosize={true} className="basic-multi-select mt-2 w-100" placeholder="Ecosystem" classNamePrefix="select" options={ECOSYSTEM_OPTIONS} />
                </Collapse> */}
              </Col>
            </Row>
          )}
        </Modal.Body>


        <Modal.Footer className='d-flex align-items-center justify-content-between py-1'>
          <Row>
            <Col md={10}>
              <Form.Check className="px-0 mb-0">
                <Form.Check.Label for="featuredOfferings" >
                  <Form.Check.Input id="featuredOfferings" size="sm" className="me-2" name="group1" type="checkbox" />
                  Marked as Featured Offerings
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="px-0 ">
                <Form.Check.Label for="agree" >
                  <Form.Check.Input id="agree" size="sm" className="me-2" name="group1" type="checkbox" />
                  By clicking Create Event, you agree to our Community Guidelines | Privacy Policy | Terms and Conditions.
                </Form.Check.Label>
              </Form.Check>
            </Col>
            <Col md={2} className='d-flex algin-items-center justify-content-between '>
              <Button size="sm" variant="secondary" onClick={handleParentClose}>
                Cancel
              </Button>

              {eventToEdit ? (
                <Button size="sm" variant="primary" onClick={handleSubmitUpdate}>{progress && (<Spinner size='sm' className="me-2" animation="border" />)} Update</Button>) : (
                <Button size="sm" variant="primary" onClick={handleSubmit}>{progress && (<Spinner size='sm' className="me-2" animation="border" />)} Add</Button>
              )}


            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  )

}
export default PVLNEvents
